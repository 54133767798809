.privacy-policy {
  &-content {
    min-height: 50vh;
    padding: 5rem 0;
    font-family: $main-font;
    &-head {
        margin-bottom: 40px;
        min-height: 40vh;
        text-align: center;
      h1 {
        font-weight: 700;
        font-size: 6rem;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        font-weight: 400;
        color: #0e0e0e;
      }
    }

    &-body {
      h3 {
        font-size: 2rem;
        font-weight: 600;
        line-height: 70px;
        letter-spacing: -1px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        font-weight: 400;
        color: #0e0e0e;
      }
    }
  }
}


@media (max-width: 767px) {
    .privacy-policy {
        &-content {
          min-height: 50vh;
          padding: 5rem 0;
          font-family: $main-font;
          &-head {
              margin-bottom: 30px;
              min-height: 30vh;
              text-align: center;
            h1 {
              font-weight: 700;
              font-size: 4rem;
            }
            p {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.3px;
              font-weight: 400;
              color: #0e0e0e;
            }
          }
      
          &-body {
            h3 {
              font-size: 1.7rem;
              font-weight: 600;
              line-height: 40px;
              letter-spacing: -2px;
            } 
            p {
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              letter-spacing: -0.078px;
            }
          }
        }
      }
}