.footer {
    border-top: 2px solid #eee;
    p.copyright {
        font-size: 12px;
        line-height: 26.32px;
        font-weight: 600;
        font-family: $sub-font;
    }
    &-content {
        p.info {
            font-size: 13px;
            line-height: 25px;
            font-weight: 400;
            font-family: $main-font;
        }
        p.number {
            font-size: 13px;
            font-family: $sub-font;
            line-height: 16.54px;
            // letter-spacing: ;
            font-weight: 600;
        }
        h6 {
            font-size: 14px;
            line-height: 28px;
            font-weight: 800;
            font-family: $sub-font;
        }
    }
    &-links {
        font-family: $sub-font;
        h6 {
            font-size: 14px;
            line-height: 28px;
            font-weight: 900;
            font-family: $sub-font;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
        }
        li  {
            a {
                text-decoration: none;
                color: #000;
                font-size: 12px;
                line-height: 28px;
            }
        }
    }
}