nav {
  font-family: $sub-font;
  background-color: #fff;
  box-shadow: 0px 20px 25px 2px rgba(0, 0, 0, 0.01);
  position: sticky;
  top: 0;
  z-index: 100;
  .nav {
    .logo-box {
      order: 1;
    }
    .hamburg {
      cursor: pointer;
      display: inline-block;
      position: relative;
      margin: 10px 5px;
      z-index: 20;
      margin-left: 15px;
      background-color: transparent;
      span {
        background-color: rgba(91, 106, 119, 0.95);
        display: block;
        height: 0.133em;
        margin-bottom: 0.28em;
        position: relative;
        top: 0;
        transition: all 0.3s ease-in-out;
        &:nth-of-type(1) {
          width: 1.25em;
        }
        &:nth-of-type(2) {
          width: 1.8em;
        }
        &:nth-of-type(3) {
          width: 1.5em;
        }
      }
    }

    // &-box {
    //   display: flex;
    //   flex-grow: 1;
    //   justify-content: space-between;
    //   align-items: center;
    &-box-list {
      flex: 1;
      order: 2;
      li {
        display: inline-block;
        margin: 0 15px 0 0;
        a {
          text-decoration: none;
          color: $dark-blue;
          font-size: 15px;
          position: relative;
          padding: 20px 30px;

          .nav-svg {
            position: absolute;
            top: 20%;
            left: 0;
            z-index: 100;
            width: 100px;
            path {
              fill: transparent;
              // stroke-width: 3;
              stroke: $dark-blue;
              stroke-dasharray: 915;
              stroke-dashoffset: 915;
            }
          }

          &:hover {
            //   padding: 20px 40px;
            .nav-svg {
              path {
                animation: animate-svg ease-in-out 2s forwards;
              }
            }
          }
        }

        a.active {
          .nav-svg {
            width: 110px;
            path {
              stroke-dasharray: 0;
            }
          }
        }
      }
    }
    // }

    &-buttons {
      order: 3;
      button {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
}

.auth-nav-box {
  font-family: $sub-font;
  background-color: #fff;
  box-shadow: 0px 20px 25px 2px rgba(0, 0, 0, 0.01);
  position: sticky;
  top: 0;
  z-index: 100;
  .auth-nav {
    .hamburg {
      cursor: pointer;
      display: inline-block;
      position: relative;
      margin: 10px 5px;
      z-index: 20;
      margin-left: 15px;
      background-color: transparent;
      span {
        background-color: rgba(91, 106, 119, 0.95);
        display: block;
        height: 0.133em;
        margin-bottom: 0.28em;
        position: relative;
        top: 0;
        transition: all 0.3s ease-in-out;
        &:nth-of-type(1) {
          width: 1.25em;
        }
        &:nth-of-type(2) {
          width: 1.8em;
        }
        &:nth-of-type(3) {
          width: 1.5em;
        }
      }
    }
    li {
      display: inline-block;
      margin: 0 15px 0 0;
      a {
        text-decoration: none;
        color: $dark-blue;
        font-size: 15px;
        position: relative;
        padding: 20px 30px;

        .nav-svg {
          position: absolute;
          top: 20%;
          left: 0;
          z-index: 100;
          width: 100px;
          path {
            fill: transparent;
            // stroke-width: 3;
            stroke: $dark-blue;
            stroke-dasharray: 915;
            stroke-dashoffset: 915;
          }
        }

        &:hover {
          //   padding: 20px 40px;
          .nav-svg {
            path {
              animation: animate-svg ease-in-out 2s forwards;
            }
          }
        }
      }
    }

    &-buttons {
      order: 3;
      button {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  inset: 0;
  // height: 100%;
  height: 120vh;
  width: 100vw;
  background-color: $dark-blue;
  // background-color: rgba(91, 106, 119, 0.95);
  // background-color: #fff;
  z-index: 1000;
  padding: 20px;
  transform: translateX(-100%);
  transition: all 0.3s ease-in;
  border-top: 5px solid $main-blue;
  .close-hamburg {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 10px 5px;
    margin-left: 15px;
    background-color: transparent;
    color: #fff;
    div {
      span {
        // background-color: rgba(1,27,51,0.95);
        background-color: #fff;
        display: block;
        height: 0.133em;
        margin-bottom: 0em;
        position: relative;
        top: 0;
        transition: all 0.3s ease-in-out;
        &:nth-of-type(1) {
          width: 1.25em;
          transform: rotateZ(135deg);
        }
        &:nth-of-type(2) {
          width: 1.25em;
          transform: rotateZ(-115deg);
        }
      }
    }
  }

  &-content {
    font-weight: 600;
    letter-spacing: -1px;
    h6 {
      font-size: 16px;
      font-family: $main-font;
      margin-bottom: 15px;
      color: $main-blue;
      font-weight: 600;
    }
    &-links {
      ul {
        padding-left: 0;
        list-style-type: none;
        li {
          margin-bottom: 5px;
          a {
            text-decoration: none;
            color: $grey;
            font-size: 25px;
            font-family: $main-font;
            text-transform: uppercase;
            letter-spacing: -1px;
            font-weight: 700;
          }
        }
      }
    }

    &-contact {
      a,
      p {
        text-decoration: none;
        // color: $dark-blue;
        color: #fff;
        font-size: 18px;
        font-family: $main-font;
        display: block;
        font-weight: 600;
      }
    }
  }
}

.mobile-nav.active {
  transform: translateX(0);
  transition: all 0.3s ease-in;
}

// @media (max-width: 576px) {
//     nav {
//         font-family: $sub-font;
//         background-color: #fff;
//         box-shadow: 0px 20px 25px 2px rgba(0, 0, 0, 0.01);
//         position: sticky;
//         top: 0;
//         z-index: 100;
//     }
// }

// .nav-linkk {
//   position: relative;
//   padding: 20px 40px;
//   .nav-svg {
//     position: absolute;
//     top: 20%;
//     left: 0;
//     z-index: 100;
//   }
// }

@keyframes animate-svg {
  0% {
    stroke-dashoffset: 915;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  nav {
    .nav {
      .logo-box {
        order: 1;
        flex: 1;
      }
      &-box-list {
        flex: 1 0 100% !important;
        order: 3;
        margin-top: 10px;
        padding-left: 0;
        justify-content: center;
      }

      &-buttons {
        order: 2;
      }
    }
  }
}

@media (max-width: 991px) {
  nav {
    .nav {
      .logo-box {
        order: 0;
        flex: 1;
      }
    }
  }
}
