.app-section {
  .blue-box {
    background-color: $main-blue;
    // min-height: 467px;
    color: #fff;
    font-family: $main-font;
    border-radius: 8px;
    .app-content {
      // padding: 50px;
      h2 {
        font-size: 3.5rem;
        letter-spacing: -2px;
        font-weight: 600;
        line-height: 70px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.08px;
        font-weight: 500;
        // width: 80%;
      }
    }
    .app-buttons-div {
      div {
        button {
          border-radius: 46px;
          padding: 13px 45px;
          font-size: 15px;
          border: 1px solid #fff;
          background-color: $main-blue;
          color: white;
          font-family: $main-font;
          letter-spacing: -0.73px;
          font-weight: 600;
        }
      }
    }
  }
}

.why-section {
  &-content {
    font-family: $main-font;
    color: #000;
    h3 {
      font-size: 3rem;
      line-height: 70px;
      font-weight: 600;
      letter-spacing: -2px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.078px;
    }
  }
}

@media (max-width: 576px) {
  .app-section {
    .blue-box {
      .app-content {
        h2 {
          font-size: 2.2rem;
          letter-spacing: -2px;
          font-weight: 600;
          line-height: 40px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.08px;
          font-weight: 500;
          width: 100%;
        }
      }
      .app-buttons-div {
        div {
          button {
            border-radius: 42px;
            padding: 13px 35px;
            font-size: 14px;
            min-width: 150px;
            letter-spacing: -0.73px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .why-section {
    &-content {
      h3 {
        font-size: 2.5rem;
        line-height: 50px;
        font-weight: 600;
        letter-spacing: -2px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.078px;
      }
    }
  }
}

.feature-video {
  width: 85%;
  height: 350px;
}

@media (max-width: 600px) {
  .feature-video {
    width: 95%;
    height: 315px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .app-section {
    .blue-box {
      border-radius: 8px;
      .app-content {
        h2 {
          font-size: 3rem;
          letter-spacing: -2px;
          line-height: 50px;
        }
      }
      .app-buttons-div {
        div {
          button {
            border-radius: 36px;
            padding: 13px 25px;
            font-size: 13px;
          }
        }
      }
    }
  }
}
