.testimonials {
    position: relative;
    font-family: $main-font;
    min-height: 30vh;
    .bubble{
        width: 25px;
        height: 25px;
        border-radius: 50%; 
        position: absolute; 
    }
    .purple-bubble {
        color: $purple;
        background-color: $purple;
        right: 30%;
        top: 20%; 
    }
    .yellow-bubble {
        color: $yellow;
        background-color: $yellow;
        right: 10%;
        top: 10%;
    }

    .lemon-bubble {
        color: $lemon;
        background-color: $lemon;
        right: 15%;
        top: 30%;
    }
    .blue-bubble {
        color: $main-blue;
        background-color: $main-blue;
        right: 20%;
        top: 15%;
    }
    &-header {
        h3 {
            font-size: 2.25rem;
            font-weight: 600;
            letter-spacing: -3px;
            line-height: 44px;
        }
    }

    &-card {
        max-width: 350px;
        padding: 18px;
        margin: 20px; 
        width: 300.57px;
        height: 192.06px; 
        background: #FFFFFF;
        box-shadow: 0px 2.69284px 23.5624px rgba(0, 0, 0, 0.08);
        border-radius: 16.8303px; 
        &-header {
            display: flex;
            align-items: center;
            gap: 15px;
            padding-bottom: 15px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            h6 {
                color: $dark-blue;
                font-family: $sub-font;
                font-style: normal;
                font-weight: 600;
                font-size: 14.6237px;
                line-height: 18px;
                color: #112957; 
                opacity: 0.9; 
                margin-bottom: 0;
            }
            p {
                font-family: $sub-font;
                font-style: normal;
                font-weight: 400;
                font-size: 12.5346px;
                line-height: 18px; 
                color: #000000; 
                opacity: 0.8;
                margin-bottom: 0;
            }
        }

        &-body {
            p {
                font-family: $sub-font;
                font-style: normal;
                font-weight: 400;
                font-size: 11.7586px;
                line-height: 19px; 
                color: #000000;
            }
        }
    }
}

@media (max-width: 576px) {
    .testimonials { 
        min-height: 60vh;
        &-header {
            h3 {
                font-size: 2.2rem;
                font-weight: 600; 
                line-height: 38px;
            }
        }
    }
}