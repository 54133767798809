.faq-header {
  min-height: 70vh;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../images/Faq.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &-content {
    font-family: $main-font;
    h1 {
      font-size: 3.5rem;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -2px;
      color: #fff;
    }
    .search-section {
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02px;
        font-weight: 500;
        color: #fff;
      }

      .box {
        position: relative;
        input {
          width: 80%;
          padding: 13px 10px 13px 38px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.3);
          box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.1);
          border-radius: 60px;
          outline: none;
          caret-color: #fff;
        }

        img {
          position: absolute;
          left: 80px;
          top: 13px;
        }
      }
    }
  }
}

.faq {
  min-height: 50vh;
  overflow-x: hidden;
  font-family: $main-font;

  .accordion-button::after {
    //   background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(0.7) !important;
    color: $main-blue;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url(../images/minus.svg);
    background-size: 20px;
  }
  .accordion-button:not(.collapsed) {
    color: #fff;
    background: $main-blue !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
  }

  .accordion-body {
    color: #fff;
    background: $main-blue !important;
    p {
      color: #fff;
      font-size: 16px;
    }
  }
  .accordion-button {
    border: 1px solid $main-blue;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
    color: #000000;
    outline: none;
  }
  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-item {
    border: none;
  }
}

@media (max-width: 576px) {
  .faq-header {
    min-height: 90vh;
    &-content {
      font-family: $main-font;
      h1 {
        font-size: 3rem;
        line-height: 50px;
      }
      .search-section {
        p {
          font-size: 15px;
          line-height: 24px;
        }

        .box {
          input {
            width: 100%;
          }
        }
      }
    }
  }

  .faq {
    min-height: 50vh;
    .accordion-button:not(.collapsed) {
      font-size: 16px;
      line-height: 25px;
    }

    .accordion-body {
      color: #fff;
      background: $main-blue !important;
      p {
        color: #fff;
        font-size: 13px;
      }
    }
    .accordion-button {
      font-weight: 600;
      font-size: 15px;
      line-height: 25px;
    }
  }
}

@media (max-width: 340px) {
  .faq-header {
    min-height: 100vh;
    &-content {
      h1 {
        font-size: 2.5rem;
        line-height: 40px;
      }
      .search-section {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
