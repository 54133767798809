@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.cdnfonts.com/css/copperplate-gothic-light");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@import "./config";
@import "./infoBanner";
@import "./nav";
@import "./header";
@import "./home";
@import "./exampass";
@import "./testimonials";
@import "./news";
@import "./contact";
@import "./footer";
@import "./about";
@import "./services";
@import "./pricing";
@import "./team";
@import "./faq";
@import "./auth";
@import "./bubble";
@import "./privacy-policy";
@import "./blog";
@import "./dashboard";
@import "./dashboard-pages";
@import "./partnership";

html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  color: #000;
  width: 100vw;
  overflow-x: hidden;
}

.tx-fraunce {
  font-family: $fraunce;
}
.main-btn {
  background-color: $main-blue;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 11.75px;
  letter-spacing: -0.73px;
  font-family: $main-font;
  min-width: 220px;
  &:hover {
    background-color: $main-blue;
    color: white;
  }
}

.trans-btn {
  min-width: 210px;
  min-height: 50px;
  background-color: transparent;
  color: #000;
  padding: 13px 35px;
  outline: none;
  border: 0.734177px solid #000000;
  filter: drop-shadow(0px 2.93671px 25.6962px rgba(75, 158, 216, 0.1));
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 12px;
  letter-spacing: -0.734px;
  font-family: $main-font;
  &:hover {
    background-color: transparent;
    color: #000;
  }
}

.purple-outline-btn {
  min-width: 220px;
  min-height: 50px;
  background-color: transparent;
  color: $purple;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid $purple;
  filter: drop-shadow(0px 2.93671px 25.6962px rgba(75, 158, 216, 0.1));
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 12px;
  letter-spacing: -0.734px;
  font-family: $main-font;
  &:hover {
    background-color: transparent;
    color: $purple;
  }
}

.white-btn {
  min-width: 220px;
  background-color: transparent;
  color: #fff;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #fff;
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.734px;
  font-family: $main-font;
  &:hover {
    background-color: transparent;
    color: #fff;
  }
}

.btn-blue {
  background-color: $main-blue;
  color: white;
  &:hover {
    background-color: $main-blue;
    color: white;
  }
}

.light-btn {
  background-color: $light-blue;
  color: #000;
  border: 1px solid $light-blue;
  outline: none;
  &:hover {
    background-color: $light-blue;
    color: #000;
    border: 1px solid $light-blue;
    outline: none;
  }
}

.tx-blue {
  color: $main-blue;
}

.tx-green {
  color: $green;
}

.tx-purple {
  color: $purple;
}

.tx-orange {
  color: $orange;
}

.bg-green {
  background-color: $green;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-red {
  background-color: $red;
}
.stat-section {
  background-color: $grey;
  font-family: $main-font;
  &-content {
    .stat {
      h3 {
        font-size: 44px;
        letter-spacing: -1.95px;
        font-weight: 700;
      }
      h6 {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;
      }
    }
  }
}

.login-btn {
  // background-color: $light-blue;
  color: #000;
  padding: 10px;
  min-width: 150px;
  font-size: 14px;
  font-family: $sub-font;
  font-weight: 900;
  outline: none;
  border-color: #000;
  border-radius: 3px;
  background-color: transparent;
  border-width: 1px;
}

.get-started-btn {
  background-color: $main-blue;
  color: #fff;
  padding: 10px;
  min-width: 150px;
  font-size: 14px;
  font-family: $sub-font;
  font-weight: 900;
  outline: none;
  border: 2px solid $main-blue;
  border-radius: 3px;
}

.purple-btn {
  background-color: #7769e2;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 11.75px;
  letter-spacing: -0.73px;
  font-family: $main-font;
  min-width: 220px;
  &:hover {
    background-color: #7769e2;
    color: white;
  }
}

.orange-btn {
  background-color: $orange;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 11.75px;
  letter-spacing: -0.73px;
  font-family: $main-font;
  min-width: 220px;
  &:hover {
    background-color: $orange;
    color: white;
  }
}

.green-btn {
  background-color: $green;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 11.75px;
  letter-spacing: -0.73px;
  font-family: $main-font;
  min-width: 220px;
  &:hover {
    background-color: $green;
    color: white;
  }
}

.a1-box {
  background: #ffffff;
  border-radius: 18.5383px;

  box-shadow: 0px 2.69284px 23.5624px rgba(0, 0, 0, 0.04);
  h6 {
    font-family: $main-font;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;

    letter-spacing: -0.02px;
    text-transform: capitalize;

    color: #000000;
  }
}

@media (max-width: 576px) {
  .main-btn,
  .trans-btn,
  .purple-outline-btn,
  .purple-btn,
  .orange-btn,
  .green-btn {
    padding: 13px 25px;
    border-radius: 46.9873px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.73px;
    min-width: 150px;
  }

  .stat-section {
    &-content {
      .stat {
        h3 {
          font-size: 2.5rem;
          letter-spacing: -1.95px;
          font-weight: 700;
        }
        h6 {
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: -1.95px;
        }
      }
    }
  }
}

@media (max-width: 376px) {
  .main-btn,
  .trans-btn,
  .purple-outline-btn,
  .purple-btn,
  .orange-btn,
  .green-btn {
    padding: 10px 20px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.73px;
    min-width: 100px;
  }
}

@media (max-width: 316px) {
  .main-btn,
  .trans-btn,
  .purple-outline-btn,
  .purple-btn,
  .orange-btn,
  .green-btn {
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: -0.73px;
    min-width: 80px;
  }
  .app-btn {
    width: 100px;
  }
}

.success-box {
  h1 {
    font-family: $main-font;
    font-size: 23.2332px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -0.02em;
  }
}

.download-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index: 10000;
  &-box {
    background-color: #fff;
    min-height: 200px;
    border-radius: 24px;
    position: relative;

    .close {
      position: absolute;
      top: 10px;
      right: 20px;
      background-color: transparent;
      outline: none;
      border: none;
    }
    &-header {
      font-family: $header-font;
      text-align: center;
      margin-bottom: 30px;
      letter-spacing: -2px;
    }
    &-body {
      button {
        display: block;
        position: relative;
        outline: none;
        padding: 3px 25px;
        text-align: center;
        border-radius: 32px;
        background-color: $main-blue;
        border: 1px solid $main-blue;
        color: #000;
        font-weight: 600;
        margin-bottom: 14px;
        span.text {
          font-size: 14px;
          font-family: $sub-font;
        }
        span {
          svg {
            width: 20px;
          }
        }
        .coming-soon {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 100%;
          height: 100%;
          border-radius: inherit;
          background-color: rgba($color: #000000, $alpha: 0.6);
          text-align: end;
          img {
            width: 60px;
            rotate: 15deg;
          }
        }
      }
    }
  }
}
