.exam-pass {
    background-color: $light-blue; 
    width: 100vw;
    position: relative;
    padding: 90px auto;
    &-content {
        font-family: $main-font;
        color: #000;
        // .dynamic-text {
        //     background-color: red;
        //     min-height: 300px;
        // }
        h3 {
            font-size: 3rem;
            line-height: 60px;
            letter-spacing: -2px;
            font-weight: 600; 
        }
        h5 {
            font-size: 1.2rem;
            line-height: 24px;
            letter-spacing: -0.6px;
            font-weight: 600;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: -0.2px;
        } 
    }
    &-phone {   
        &-img-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;  
            img {
                // position: absolute;
                max-height: 70vh;
                // margin-top: 15vh;
            }
        }
    } 
}


@media (max-width: 1400px) {
    .exam-pass {
        padding: 90px auto;
        &-phone {   
            &-img-wrapper { 
                img { 
                    max-height: 85vh;
                }
            }
        } 
    }
}



@media (max-width: 576px) {
    .exam-pass {
        background-color: $light-blue;
        &-content {
            font-family: $main-font;
            color: #000;
            h3 {
                font-size: 2.5rem;
                line-height: 50px;
                letter-spacing: -2px;
                font-weight: 600; 
            }
            h5 {
                font-size: 1.2rem;
                line-height: 24px;
                letter-spacing: -0.6px;
                font-weight: 600;
            }
            p {
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                letter-spacing: -0.2px;
            }
        }
        &-phone {   
            &-img-wrapper { 
                img { 
                    max-height: 30vh;
                }
            }
        } 
    }
}

.exam-pass.active {
    position: sticky;
    top: 100px;
}

@media(min-width: 768px) and (max-width: 992px) {
    .exam-pass {
        background-color: $light-blue; 
        width: 100vw;
        position: relative;
        padding: 90px auto;
        &-content {
            font-family: $main-font;
            color: #000;
            h3 {
                font-size: 2.5rem;
                line-height: 50px;
                letter-spacing: -2px;
                font-weight: 600; 
            }
            h5 {
                font-size: 1.2rem;
                line-height: 24px;
                letter-spacing: -0.6px;
                font-weight: 600;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                letter-spacing: -0.2px;
            } 
        }
        &-phone {   
            &-img-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;  
                img {
                    // position: absolute;
                    max-height: 60vh;
                    // margin-top: 15vh;
                }
            }
        } 
    }
}