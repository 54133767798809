.home-header {
  min-height: 80vh;
  padding-top: 5rem;
  text-align: center;
  position: relative;
  .bubble {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
  }

  .purple-bubble {
    color: $purple;
    background-color: $purple;
    right: 30%;
    top: 20%;
  }
  .yellow-bubble {
    color: $yellow;
    background-color: $yellow;
    right: 10%;
    top: 10%;
  }

  .lemon-bubble {
    color: $lemon;
    background-color: $lemon;
    right: 15%;
    top: 30%;
  }
  .blue-bubble {
    color: $main-blue;
    background-color: $main-blue;
    right: 20%;
    top: 15%;
  }
  .header-content {
    div {
      h1 {
        font-family: $header-font;
        font-size: 50px;
        // letter-spacing: -4px;

        // letter-spacing: -4.52241px;
        letter-spacing: -2px;
        line-height: 72px;
        font-weight: 700;
        color: #000;
      }
      h5 {
        font-size: 16px;
        font-weight: 500;
        font-family: $main-font;
        line-height: 19.5px;
        letter-spacing: -0.6px;
      }
      img {
        width: 95%;
      }
    }
  }
}

@media (max-width: 576px) {
  .home-header {
    padding-top: 5.3rem;
    text-align: center;
    overflow-x: hidden;
    .bubble {
      width: 15px;
      height: 15px;
      font-size: 1px;
      z-index: -1;
    }
    .header-content {
      div {
        h1 {
          //   font-family: $main-font;
          font-size: 2rem;
          letter-spacing: -1px;
          line-height: 50px;
          font-weight: 900;
          color: #000;
        }
        h5 {
          font-size: 13px;
          font-weight: 500;
          font-family: $main-font;
          line-height: 13.5px;
        }
        img {
          width: 90%;
        }
      }
    }
  }
}
