.pricing-header {
  min-height: 85vh;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ), 
    url("../images/pricing.jpeg");
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &-content {
    font-family: $main-font;
    h1 {
      font-size: 3.5rem;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -2px;
      color: #fff;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02px;
      font-weight: 500;
      color: #fff;
    }
  }
}

.invest {
  min-height: 90vh;
  background-image: url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661309654/a1tutor/rafiki_wjccfb.png);
  background-size: 60%;
  background-position: 100% 70%;
  background-repeat: no-repeat;
  &-header {
    font-family: $main-font;
    h3 {
      font-size: 3rem;
      line-height: 60px;
      font-weight: 600;
      letter-spacing: -2px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.1px;
      font-weight: 600;
      color: #555555;
    }
  }

  &-content {
    .box {
      border-radius: 4px;
      color: white;
      font-family: $main-font;
      .price-header {
        min-height: 150px;
        h5 {
          font-size: 23.2332px;
          line-height: 28px;
          font-weight: 600;
          letter-spacing: -0.02em;
        }
        h6.slashed-price {
          font-size: 30px;
          line-height: 37px;
          font-weight: 600;
          letter-spacing: -0.03em;
          position: relative;
          &::after {
            content: url(../images/slashed-red.png);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
        h4 {
          font-size: 2.25rem;
          line-height: 44px;
          font-weight: 600;
          letter-spacing: -0.03em;
          span.month {
            font-size: 1.5rem;
            line-height: 29px;
            letter-spacing: -0.05em;
            font-weight: 500;
            font-family: $main-font;
          }
        }
      }
      .price-body {
        &-item {
          border-top: 1px solid #fff;
          display: flex;
          gap: 5px;
          p {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.2px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }
    }

    .box.one {
      background-color: $purple;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }

    .box.two {
      background-color: $lemon;
    }

    .box.three {
      background-color: $yellow;
    }
  }
}

@media (min-width: 992px) and (max-width: 1115px) {
  .invest {
    &-content {
      .box {
        border-radius: 4px;
        .price-header {
          h5 {
            font-size: 18.2332px;
            line-height: 28px;
            font-weight: 600;
            letter-spacing: -0.05em;
          }
          h6.slashed-price {
            font-size: 25px;
            line-height: 32px;
          }
          h4 {
            font-size: 2rem;
            line-height: 40px;
            span.month {
              font-size: 1.2rem;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .pricing-header {
    min-height: 100vh;
    &-content {
      font-family: $main-font;
      h1 {
        font-size: 3rem;
        line-height: 50px;
        letter-spacing: -4px;
      }
      p {
        font-size: 15px;
      }
    }
  }

  .invest {
    min-height: 90vh;
    background-size: cover;
    background-position: center;
    &-header {
      h3 {
        font-size: 2.5rem;
        line-height: 60px;
        letter-spacing: -4px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-content {
      .box {
        .price-header {
          min-height: auto;
          padding: 20px auto;
          h5 {
            font-size: 18.2332px;
            line-height: 28px;
            font-weight: 600;
            letter-spacing: -0.05em;
          }
          h6.slashed-price {
            font-size: 25px;
            line-height: 32px;
          }
          h4 {
            font-size: 2rem;
            line-height: 40px;
            span.month {
              font-size: 1.2rem;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .pricing-header {
    min-height: 120vh;
    &-content {
      h1 {
        font-size: 2.5rem;
      }
      p {
        font-size: 15px;
      }
    }
  }

  .invest {
    min-height: 90vh;
    background-size: cover;
    background-position: center;
    &-header {
      h3 {
        font-size: 2.5rem;
        line-height: 40px;
        letter-spacing: -4px;
      }

      p {
        font-size: 15px;
        line-height: 24px;
      }
    }

    &-content {
      .box {
        border-radius: 4px;
        .price-header {
          min-height: auto;
          padding: auto auto 20px;
          h5 {
            font-size: 20px;
            line-height: 24px;
          }
          h6.slashed-price {
            font-size: 24px;
            line-height: 28px;
          }
          h4 {
            font-size: 2rem;
            line-height: 38px;
            font-weight: 600;
            letter-spacing: -0.03em;
            span.month {
              font-size: 1.2rem;
              line-height: 24px;
            }
          }
        }
        .price-body {
          &-item {
            p {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
