.top-title-bar {
  margin-bottom: 30px;
  h3 {
    font-size: 24px;
    letter-spacing: -1px;
    color: #615e83;
    font-weight: 700;
  }
}

.subcription-cards {
  .box {
    border-radius: 4px;
    color: white;
    font-family: $main-font;
    .price-header {
      //   min-height: 150px;
      h5 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: -0.02em;
      }
      h6.slashed-price {
        font-size: 25px;
        line-height: 30px;
        font-weight: 600;
        letter-spacing: -0.03em;
        position: relative;
        &::after {
          content: url(../images/slashed-red.png);
          position: absolute;
          top: 0;
          left: -10px;
          width: 100%;
        }
      }
      h4 {
        font-size: 2rem;
        line-height: 38px;
        font-weight: 600;
        letter-spacing: -0.03em;
        span.month {
          font-size: 1.2rem;
          line-height: 29px;
          letter-spacing: -0.03em;
          font-weight: 500;
        }
      }
    }
    .price-body {
      //   display: none;
      &-item {
        border-top: 1px solid #fff;
        display: flex;
        gap: 5px;
        p {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.2px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
  }

  .box.one {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: #eb5757;
    // background-color: $main-blue;
  }

  .box.two {
    background-color: $yellow;
  }

  .box.three {
    // background-color: #05182d;
    background-color: $green;
  }
}

.transaction {
  .transaction-box {
    color: #fff;

    height: 100.87px;

    background: #02c869;
    box-shadow: 0px 14.0495px 77.2723px -21.0743px rgba(43, 37, 37, 0.12);
    border-radius: 11.5278px;
    padding: 20px;
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 15.3704px;
      color: #f2f2f2;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .transaction-box.one {
    background-color: $main-blue;
  }

  .transaction-box.two {
    background-color: $yellow;
  }

  .transaction-box.three {
    background-color: $red;
  }

  &-table {
    &-title {
      font-size: 20px;
      color: #615e83;
      font-weight: 600;
      letter-spacing: -0.6px;
    }
    &-box {
      width: 100%;
      overflow-x: auto;
      table {
        background-color: none !important;
        border-collapse: separate;
        border-spacing: 0px 8px !important;
        width: 100%;
        font-size: 12px;
      }

      thead tr {
        background-color: $main-blue;
        text-align: center;
        font-family: $DM-san;
        color: #fff;
      }
      tbody tr {
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        text-align: center;
        font-size: 14px;
        border-radius: 20px !important;
        &:hover {
          background-color: $main-blue !important;
          color: #fff !important;
          a {
            color: #fff;
          }
        }
      }

      tbody tr td,
      thead tr th {
        padding: 1rem !important;
        white-space: nowrap;
      }

      thead th {
        text-transform: uppercase;
      }
    }
  }
}

.coupon-box {
  label {
    font-size: 13px;
  }

  input {
    padding: 10px;
    border: 1px solid white;
    outline: none;
    width: 100%;
    max-width: 300px;
    display: block;
    border-radius: 4px;
    font-size: 14px;
  }
}
