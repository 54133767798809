.partnership-page {
  font-family: $main-font;
  &_header {
    min-height: 500px;
    &_first_box {
      color: $dark-blue;
      background-color: #fff;
      &_title {
        font-size: 5rem;
        font-weight: 600;
        color: $main-blue;
        letter-spacing: -2px;
        line-height: 6rem;
        span {
          color: $dark-blue;
        }
      }
    }

    &_second_box {
      &_text_box {
        background-color: $main-blue;
        color: #fff;
        transition: all 0.2s ease-in-out;
        .collaborative-initiative-box {
          h5 {
            padding: 6px 10px;
            background-color: #fff;
            color: #112957;
            font-size: 14px;
            display: inline-block;
            border-radius: 10px;
            text-transform: uppercase;
          }
          h3 {
            color: #fff;
            font-size: 22px;
          }
        }

        .who-can-collaborate {
          margin-top: 50px;
          min-height: 200px;
          h3 {
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: -2px;
            line-height: 3rem;
          }

          &-box {
            background-color: #fff;
            min-height: 300px;
            border-radius: 10px;
            color: $dark-blue;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-align: left;
            padding: 30px 20px;

            .content-box {
              h5 {
                font-size: 2.2rem;
                font-weight: 600;
                letter-spacing: -1px;
                line-height: 2.5rem;
              }
              p {
                font-size: 13px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &_text_box.active {
        margin: 30px 50px;
        border-radius: 30px;
      }
    }
  }

  // &_section {
  .benefit-of-collaboration {
    font-family: $main-font;
    &-header {
      h3 {
        font-size: 3rem;
        font-weight: 600;
        letter-spacing: -1.5px;
        line-height: 3rem;
        // color: $main-blue;
        color: #fff;
        // color: $dark-blue;
      }
    }

    &-body {
      &-content-box {
        border-top: 0.5px solid #fff;

        border-bottom: 0.5px solid #fff;
        padding: 40px 0;

        h5 {
          font-size: 1.8rem;
          font-weight: 600;
          letter-spacing: -0.5px;
          line-height: 3rem;
          // color: $dark-blue;
          color: #fff;
        }
        p {
          // color: $dark-blue;
          color: #fff;
          font-size: 18px;
          letter-spacing: -0.4px;
        }
      }
    }
  }
  // }

  &-contact-section {
    font-family: $main-font;
    min-height: 400px;
    &-header {
      position: relative;
      h3 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -3px;
        color: $main-blue;
      }
    }
    &-info {
      min-height: 40vh;
      background-image: linear-gradient(
          0deg,
          rgba(119, 105, 226, 0.7),
          rgba(119, 105, 226, 0.7)
        ),
        url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661259768/a1tutor/school-boys_csdfkp.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 33.57px;
        letter-spacing: -1.5px;
      }

      img {
        width: 27px;
      }
    }

    &-form {
      form {
        input,
        textarea {
          padding: 18px;
          width: 100%;
          border-radius: 8px;
          background-color: rgba(237, 237, 237, 0.7);
          font-size: 16px;
          letter-spacing: -1px;
          outline: none;
          color: rgba($color: #000000, $alpha: 0.45);
          line-height: 28.4px;
          font-weight: 500;
          border: 1px solid #f5f5f5;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .partnership-page {
    &_header {
      &_first_box {
        &_title {
          font-size: 2.5rem;
          line-height: 3rem;
          span {
            color: $dark-blue;
          }
        }
      }

      &_second_box {
        &_text_box {
          .collaborative-initiative-box {
            h5 {
              padding: 6px 10px;
              background-color: #fff;
              color: #112957;
              font-size: 12px;
              display: inline-block;
              border-radius: 10px;
              text-transform: uppercase;
            }
            h3 {
              color: #fff;
              font-size: 15px;
              line-height: 16px;
            }
          }

          .who-can-collaborate {
            margin-top: 50px;
            min-height: 200px;
            h3 {
              font-size: 2rem;
              font-weight: 600;
              letter-spacing: -2px;
              line-height: 2rem;
            }

            &-box {
              min-height: 250px;

              .content-box {
                h5 {
                  font-size: 1.8rem;
                  letter-spacing: -0.4px;
                  line-height: 2.5rem;
                }
                p {
                  font-size: 13px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &_text_box.active {
          margin: 20px 10px;
          border-radius: 20px;
        }
      }
    }

    // &_section {
    .benefit-of-collaboration {
      font-family: $main-font;
      &-header {
        h3 {
          font-size: 2.3rem;
          font-weight: 600;
          letter-spacing: -1px;
          line-height: 2.5rem;
          // color: $main-blue;
          // color: $dark-blue;
        }
      }

      &-body {
        &-content-box {
          padding: 15px 0;

          h5 {
            font-size: 1.3rem;
            font-weight: 600;
            letter-spacing: -0.5px;
            line-height: 2rem;
          }
          p {
            font-size: 13px;
            letter-spacing: -0.4px;
          }
        }
      }
    }
    // }

    &-contact-section {
      font-family: $main-font;
      min-height: 400px;
      &-header {
        position: relative;
        h3 {
          font-size: 2.3rem;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -2px;
          color: $main-blue;
        }
      } 

      &-form {
        form {
          input,
          textarea {
            padding: 15px;
            width: 100%;
            border-radius: 8px;
            background-color: rgba(237, 237, 237, 0.7);
            font-size: 14px;
            letter-spacing: 0px;
            outline: none;
            color: rgba($color: #000000, $alpha: 0.45);
            line-height: 28.4px;
            font-weight: 500;
            border: 1px solid #f5f5f5;
          }
        }
      }
    }
  }
}
