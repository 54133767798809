.team {
  font-family: $main-font;
  text-align: center;
  &-header {
    h3 {
      font-size: 40px;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -2px;
      color: $dark-blue;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0784237px;
    color: #0e0e0e;
    font-weight: 600;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0784237px;
    color: #0e0e0e;
  }
}
