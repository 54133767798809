.blog {
  &-content {
    min-height: 50vh;
    padding: 5rem 0;
    font-family: $main-font;
    &-head {
      margin-bottom: 40px;
      min-height: 40vh;
      text-align: center;
      h1 {
        font-weight: 700;
        font-size: 6rem;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        font-weight: 400;
        color: #0e0e0e;
      }
    }

    &-body {
      h3 {
        font-size: 2rem;
        font-weight: 600;
        // line-height: 70px;
        letter-spacing: -1px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        font-weight: 400;
        color: #0e0e0e;
      }
    }
  }
}

.single-blog {
  min-height: 50vh;
  padding: 3rem 0;
  font-family: $main-font;
  &-head {
    .blog-title {
      font-size: 2.6rem !important;
      color: #0e0e0e;
    }
    .banner-img {
      width: 100%;
      height: auto;
      border: 2px solid #bbb;
      border-radius: 5px;
      // max-height: 70vh;
    }
  }
}

@media (max-width: 767px) {
  .blog {
    &-content {
      min-height: 50vh;
      padding: 3em 0;
      font-family: $main-font;
      &-head {
        margin-bottom: 40px;
        min-height: 40vh;
        text-align: center;
        h1 {
          font-weight: 700;
          font-size: 6rem;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          font-weight: 400;
          color: #0e0e0e;
        }
      }

      &-body {
        h3 {
          font-size: 2rem;
          font-weight: 600;
          // line-height: 70px;
          letter-spacing: -1px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          font-weight: 400;
          color: #0e0e0e;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .blog {
    &-content {
      &-head {
        min-height: 20vh;
        h1 {
          font-size: 4rem;
        }
      }
      &-body {
        h3 {
          font-size: 1.7rem;
          font-weight: 600;
          // line-height: 40px;
          letter-spacing: -0.5px;
          margin-top: 5px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          letter-spacing: -0.078px;
        }
      }
    }
  }
  .single-blog {
    &-head {
      .blog-title {
        font-size: 1.4rem;
      }
    }
    a {
      color: #000;
    }

    &-body {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.blog-loader {
  // height: 60vh;
  display: grid;
  place-content: center;
  .spinner {
    position: relative;
    width: 16px;
    height: 16px;
  }

  .spinner div {
    width: 100%;
    height: 100%;
    background-color: #004dff;
    border-radius: 50%;
    animation: spinner-4t3wzl 1.25s infinite backwards;
  }

  .spinner div:nth-child(1) {
    animation-delay: 0.15s;
    background-color: rgba(0, 77, 255, 0.9);
  }

  .spinner div:nth-child(2) {
    animation-delay: 0.3s;
    background-color: rgba(0, 77, 255, 0.8);
  }

  .spinner div:nth-child(3) {
    animation-delay: 0.45s;
    background-color: rgba(0, 77, 255, 0.7);
  }

  .spinner div:nth-child(4) {
    animation-delay: 0.6s;
    background-color: rgba(0, 77, 255, 0.6);
  }

  .spinner div:nth-child(5) {
    animation-delay: 0.75s;
    background-color: rgba(0, 77, 255, 0.5);
  }
}

@keyframes spinner-4t3wzl {
  0% {
    transform: rotate(0deg) translateY(-200%);
  }

  60%,
  100% {
    transform: rotate(360deg) translateY(-200%);
  }
}
