.info_banner {
  background-color: $purple;
//   text-align: center;
  color: white;

  img {
    width: 44px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    font-family: $main-font;
    letter-spacing: -1px;
  }
  span {
    font-size: 15px;
    font-weight: 500;
    font-family: $main-font;
    letter-spacing: -0.1px;
  }
}

@media (max-width: 576px) {
  .info_banner {
    text-align: left;

    img {
      width: 34px;
    }
    p {
      font-size: 13px;
      font-weight: 500;
      font-family: $main-font;
      letter-spacing: -1px;
    }
    span {
        font-size: 13px;
        font-weight: 500;
        font-family: $main-font;
        letter-spacing: -0.1px;
      }
  }
}
