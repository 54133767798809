.news {
    font-family: $main-font; 
    &-header {
        h3 {
            font-size: 2.25rem;
            font-weight: 600;
            line-height: 44px;
            letter-spacing: -3px;
        }
    }
}


@media (max-width: 576px) {
    .news { 
        min-height: 60vh;
        &-header {
            h3 {
                font-size: 2.2rem;
                font-weight: 600; 
                line-height: 38px;
            }
        }
    }
}