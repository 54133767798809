.dashboard {
  width: 100vw;
  display: flex;
  font-family: $main-font;
  &-side-nav {
    width: 245px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f9fafb;
    height: 100vh;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    &_logo_box {
      padding: 5px 42px;
      display: flex;
      justify-content: space-between;
    }
    &_nav_list {
      margin-top: 40px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ul {
        padding-left: 0;
        list-style-type: none;
        li {
          p {
            cursor: pointer;
            display: flex;
            transition: all 0.2s ease-in-out;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            width: 100%;
            padding: 15px 42px;
            position: relative;
            color: rgba(200, 205, 214, 0.5);
            margin-bottom: 0;
            .list__icon {
              svg {
                path {
                  stroke: #c8cdd680;
                }
              }
            }

            .list__text {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              position: relative;
            }

            .red-info {
              position: absolute;
              top: 30%;
              right: 30%;
              background-color: #ff006e;
              color: #fff;
              padding: 0px 2px;
              font-size: 10px;
              border-radius: 3px;
              font-weight: 600;
              font-style: italic;
            }
          }
          a {
            display: flex;
            transition: all 0.2s ease-in-out;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            width: 100%;
            padding: 15px 42px;
            position: relative;
            color: #c8cdd6;
            .list__icon {
              svg {
                path {
                  stroke: #bdbdbd;
                }
              }
            }

            .list__text {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }

            &:hover {
              color: $main-light-blue;
              padding: 15px 42px 15px 46px;
              // &::after {
              //   content: "";
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   height: 100%;
              //   width: 5px;
              //   background-color: $main-light-blue;
              //   border-top-right-radius: 5px;
              //   border-bottom-right-radius: 5px;
              // }

              .list__icon {
                svg {
                  path {
                    stroke: $main-light-blue;
                    stroke-width: 1;
                  }
                }
              }

              .list__text {
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }

          a.active {
            color: $main-light-blue;
            // padding: 15px 42px 15px 50px;
            &::after {
              content: "";
              position: absolute;
              top: calc(50% - 18px);
              left: 0;
              height: 36px;
              width: 5px;
              background-color: $main-light-blue;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }

            .list__icon {
              svg {
                path {
                  stroke: $main-light-blue;
                  stroke-width: 2;
                }
              }
            }

            .list__text {
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }

      .logout__group {
        margin: 0px 30px;
        a {
          text-decoration: none;
          background-color: #fff;
          border-radius: 10px;
          padding: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          color: #c8cdd6;
          
        }

        button { 
          background-color: #fff;
          border-radius: 10px;
          padding: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          color: #c8cdd6;
          outline: none;
          border: none;
          width: 100%;
        }
      }
    }
  }

  &-main-content-layout {
    width: calc(100% - 245px);
    background-color: #f5f5f5;
    margin-left: 245px;
    min-height: 100vh;

    .top-section {
      padding: 20px;
      position: sticky;
      top: 0;
      z-index: 100;
      transition: all 0.3s ease-in;
      &-info {
        text-decoration: none;
        color: #05182d;
        &:hover {
          color: #05182d;
        }
      }

      .user {
        &-img {
          width: 45px;
        }
        &-fullname {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #615e83;
          letter-spacing: -1px;
          margin-bottom: 0;
        }

        &-location {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #c8cdd6;
          margin-bottom: 0;
        }
      }
    }
    .top-section.active {
      padding: 20px 40px;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: saturate(100%) blur(2px);
    }
    .dashboard-main-content {
      padding: 40px;
    }
  }
}

.dashboard-search {
  position: relative;
  &-input {
    background: #fafafa;
    width: 100%;
    height: 50px;
    border: 2px solid #f9fafb;
    border-radius: 112.791px;
    padding: 4px 40px 4px 16px;
    outline: 2px solid #f9fafb;
    transition: all 0.3s ease-in-out;
    position: relative;
    &:focus {
      outline: 2px solid #ddd;
    }
  }
  img {
    position: absolute;
    top: 30%;
    right: 15px;
  }
}

@media (max-width: 991px) {
  .dashboard {
    width: 100vw;
    display: flex;
    font-family: $main-font;
    &-side-nav {
      width: 100vw;
      position: fixed;
      top: 0;
      left: -100%;
      bottom: 0;
      background-color: #fff;
      height: 100vh;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      z-index: 100000;
      transition: all 0.3s ease-in-out;
      &_logo_box {
        padding: 5px 42px;
      }
      &_nav_list {
        margin-top: 40px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ul {
          padding-left: 0;
          list-style-type: none;
          li {
            p {
              display: flex;
              transition: all 0.2s ease-in-out;
              align-items: center;
              gap: 10px;
              text-decoration: none;
              width: 100%;
              padding: 15px 42px;
              position: relative;
              color: rgba(200, 205, 214, 0.5);
              margin-bottom: 0;
              .list__icon {
                svg {
                  path {
                    stroke: #c8cdd680;
                  }
                }
              }

              .list__text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                position: relative;
              }

              .red-info {
                position: absolute;
                top: 5px;
                right: 50%;
                background-color: #ff006e;
                color: #fff;
                padding: 3px;
                font-size: 10px;
                border-radius: 5px;
                font-weight: 600;
                font-style: italic;
              }
            }
            a {
              display: flex;
              transition: all 0.2s ease-in-out;
              align-items: center;
              gap: 10px;
              text-decoration: none;
              width: 100%;
              padding: 15px 42px;
              position: relative;
              color: #c8cdd6;
              .list__icon {
                svg {
                  path {
                    stroke: #bdbdbd;
                  }
                }
              }

              .list__text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
              }

              &:hover {
                color: $main-light-blue;
                padding: 15px 42px 15px 46px;
                // &::after {
                //   content: "";
                //   position: absolute;
                //   top: 0;
                //   left: 0;
                //   height: 100%;
                //   width: 5px;
                //   background-color: $main-light-blue;
                //   border-top-right-radius: 5px;
                //   border-bottom-right-radius: 5px;
                // }

                .list__icon {
                  svg {
                    path {
                      stroke: $main-light-blue;
                      stroke-width: 1;
                    }
                  }
                }

                .list__text {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 16px;
                }
              }
            }

            a.active {
              color: $main-light-blue;
              padding: 15px 42px 15px 50px;
              &::after {
                content: "";
                position: absolute;
                top: calc(50% - 18px);
                left: 0;
                height: 36px;
                width: 5px;
                background-color: $main-light-blue;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }

              .list__icon {
                svg {
                  path {
                    stroke: $main-light-blue;
                    stroke-width: 2;
                  }
                }
              }

              .list__text {
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }

        .logout__group {
          margin: 0px 30px;
          a {
            text-decoration: none;
            background-color: #fff;
            border-radius: 10px;
            padding: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
            color: #c8cdd6;
          }
        }
      }
    }

    &-side-nav.mobile-active {
      left: 0;
    }
    &-main-content-layout {
      width: 100%;
      margin-left: 0;
      .top-section.active {
        padding: 20px;
      }

      .dashboard-main-content {
        padding: 20px;
      }
    }
  }
}

