@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.cdnfonts.com/css/copperplate-gothic-light");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@font-face {
  font-family: Filson Pro;
  src: url("../fonts/16919/Filson Pro/webfont/FilsonProBook.woff2") format("woff2"), url("../fonts/16919/Filson Pro/webfont/FilsonProBook.woff") format("woff");
}
@font-face {
  font-family: Copperplate Bold;
  src: local("Copperplate"), url("../fonts/CopperplateCC-Full/CopperplateCC/otf/CopperplateCC-Bold.otf") format("truetype"), url("../fonts/CopperplateCC-Full/CopperplateCC/otf/CopperplateCC-Bold.otf") format("otf");
}
.info_banner {
  background-color: #7769e2;
  color: white;
}
.info_banner img {
  width: 44px;
}
.info_banner p {
  font-size: 16px;
  font-weight: 500;
  font-family: Filson Pro;
  letter-spacing: -1px;
}
.info_banner span {
  font-size: 15px;
  font-weight: 500;
  font-family: Filson Pro;
  letter-spacing: -0.1px;
}

@media (max-width: 576px) {
  .info_banner {
    text-align: left;
  }
  .info_banner img {
    width: 34px;
  }
  .info_banner p {
    font-size: 13px;
    font-weight: 500;
    font-family: Filson Pro;
    letter-spacing: -1px;
  }
  .info_banner span {
    font-size: 13px;
    font-weight: 500;
    font-family: Filson Pro;
    letter-spacing: -0.1px;
  }
}
nav {
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  box-shadow: 0px 20px 25px 2px rgba(0, 0, 0, 0.01);
  position: sticky;
  top: 0;
  z-index: 100;
}
nav .nav .logo-box {
  order: 1;
}
nav .nav .hamburg {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin: 10px 5px;
  z-index: 20;
  margin-left: 15px;
  background-color: transparent;
}
nav .nav .hamburg span {
  background-color: rgba(91, 106, 119, 0.95);
  display: block;
  height: 0.133em;
  margin-bottom: 0.28em;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
nav .nav .hamburg span:nth-of-type(1) {
  width: 1.25em;
}
nav .nav .hamburg span:nth-of-type(2) {
  width: 1.8em;
}
nav .nav .hamburg span:nth-of-type(3) {
  width: 1.5em;
}
nav .nav-box-list {
  flex: 1;
  order: 2;
}
nav .nav-box-list li {
  display: inline-block;
  margin: 0 15px 0 0;
}
nav .nav-box-list li a {
  text-decoration: none;
  color: #112957;
  font-size: 15px;
  position: relative;
  padding: 20px 30px;
}
nav .nav-box-list li a .nav-svg {
  position: absolute;
  top: 20%;
  left: 0;
  z-index: 100;
  width: 100px;
}
nav .nav-box-list li a .nav-svg path {
  fill: transparent;
  stroke: #112957;
  stroke-dasharray: 915;
  stroke-dashoffset: 915;
}
nav .nav-box-list li a:hover .nav-svg path {
  animation: animate-svg ease-in-out 2s forwards;
}
nav .nav-box-list li a.active .nav-svg {
  width: 110px;
}
nav .nav-box-list li a.active .nav-svg path {
  stroke-dasharray: 0;
}
nav .nav-buttons {
  order: 3;
}
nav .nav-buttons button {
  margin-left: 10px;
  font-size: 14px;
}

.auth-nav-box {
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  box-shadow: 0px 20px 25px 2px rgba(0, 0, 0, 0.01);
  position: sticky;
  top: 0;
  z-index: 100;
}
.auth-nav-box .auth-nav .hamburg {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin: 10px 5px;
  z-index: 20;
  margin-left: 15px;
  background-color: transparent;
}
.auth-nav-box .auth-nav .hamburg span {
  background-color: rgba(91, 106, 119, 0.95);
  display: block;
  height: 0.133em;
  margin-bottom: 0.28em;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.auth-nav-box .auth-nav .hamburg span:nth-of-type(1) {
  width: 1.25em;
}
.auth-nav-box .auth-nav .hamburg span:nth-of-type(2) {
  width: 1.8em;
}
.auth-nav-box .auth-nav .hamburg span:nth-of-type(3) {
  width: 1.5em;
}
.auth-nav-box .auth-nav li {
  display: inline-block;
  margin: 0 15px 0 0;
}
.auth-nav-box .auth-nav li a {
  text-decoration: none;
  color: #112957;
  font-size: 15px;
  position: relative;
  padding: 20px 30px;
}
.auth-nav-box .auth-nav li a .nav-svg {
  position: absolute;
  top: 20%;
  left: 0;
  z-index: 100;
  width: 100px;
}
.auth-nav-box .auth-nav li a .nav-svg path {
  fill: transparent;
  stroke: #112957;
  stroke-dasharray: 915;
  stroke-dashoffset: 915;
}
.auth-nav-box .auth-nav li a:hover .nav-svg path {
  animation: animate-svg ease-in-out 2s forwards;
}
.auth-nav-box .auth-nav-buttons {
  order: 3;
}
.auth-nav-box .auth-nav-buttons button {
  margin-left: 10px;
  font-size: 14px;
}

.mobile-nav {
  position: fixed;
  inset: 0;
  height: 120vh;
  width: 100vw;
  background-color: #112957;
  z-index: 1000;
  padding: 20px;
  transform: translateX(-100%);
  transition: all 0.3s ease-in;
  border-top: 5px solid #4b9dd8;
}
.mobile-nav .close-hamburg {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin: 10px 5px;
  margin-left: 15px;
  background-color: transparent;
  color: #fff;
}
.mobile-nav .close-hamburg div span {
  background-color: #fff;
  display: block;
  height: 0.133em;
  margin-bottom: 0em;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.mobile-nav .close-hamburg div span:nth-of-type(1) {
  width: 1.25em;
  transform: rotateZ(135deg);
}
.mobile-nav .close-hamburg div span:nth-of-type(2) {
  width: 1.25em;
  transform: rotateZ(-115deg);
}
.mobile-nav-content {
  font-weight: 600;
  letter-spacing: -1px;
}
.mobile-nav-content h6 {
  font-size: 16px;
  font-family: Filson Pro;
  margin-bottom: 15px;
  color: #4b9dd8;
  font-weight: 600;
}
.mobile-nav-content-links ul {
  padding-left: 0;
  list-style-type: none;
}
.mobile-nav-content-links ul li {
  margin-bottom: 5px;
}
.mobile-nav-content-links ul li a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 25px;
  font-family: Filson Pro;
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: 700;
}
.mobile-nav-content-contact a,
.mobile-nav-content-contact p {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-family: Filson Pro;
  display: block;
  font-weight: 600;
}

.mobile-nav.active {
  transform: translateX(0);
  transition: all 0.3s ease-in;
}

@keyframes animate-svg {
  0% {
    stroke-dashoffset: 915;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  nav .nav .logo-box {
    order: 1;
    flex: 1;
  }
  nav .nav-box-list {
    flex: 1 0 100% !important;
    order: 3;
    margin-top: 10px;
    padding-left: 0;
    justify-content: center;
  }
  nav .nav-buttons {
    order: 2;
  }
}
@media (max-width: 991px) {
  nav .nav .logo-box {
    order: 0;
    flex: 1;
  }
}
.home-header {
  min-height: 80vh;
  padding-top: 5rem;
  text-align: center;
  position: relative;
}
.home-header .bubble {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
}
.home-header .purple-bubble {
  color: #7769e2;
  background-color: #7769e2;
  right: 30%;
  top: 20%;
}
.home-header .yellow-bubble {
  color: #ffce31;
  background-color: #ffce31;
  right: 10%;
  top: 10%;
}
.home-header .lemon-bubble {
  color: #02c869;
  background-color: #02c869;
  right: 15%;
  top: 30%;
}
.home-header .blue-bubble {
  color: #4b9dd8;
  background-color: #4b9dd8;
  right: 20%;
  top: 15%;
}
.home-header .header-content div h1 {
  font-family: Copperplate Bold;
  font-size: 50px;
  letter-spacing: -2px;
  line-height: 72px;
  font-weight: 700;
  color: #000;
}
.home-header .header-content div h5 {
  font-size: 16px;
  font-weight: 500;
  font-family: Filson Pro;
  line-height: 19.5px;
  letter-spacing: -0.6px;
}
.home-header .header-content div img {
  width: 95%;
}

@media (max-width: 576px) {
  .home-header {
    padding-top: 5.3rem;
    text-align: center;
    overflow-x: hidden;
  }
  .home-header .bubble {
    width: 15px;
    height: 15px;
    font-size: 1px;
    z-index: -1;
  }
  .home-header .header-content div h1 {
    font-size: 2rem;
    letter-spacing: -1px;
    line-height: 50px;
    font-weight: 900;
    color: #000;
  }
  .home-header .header-content div h5 {
    font-size: 13px;
    font-weight: 500;
    font-family: Filson Pro;
    line-height: 13.5px;
  }
  .home-header .header-content div img {
    width: 90%;
  }
}
.app-section .blue-box {
  background-color: #4b9dd8;
  color: #fff;
  font-family: Filson Pro;
  border-radius: 8px;
}
.app-section .blue-box .app-content h2 {
  font-size: 3.5rem;
  letter-spacing: -2px;
  font-weight: 600;
  line-height: 70px;
}
.app-section .blue-box .app-content p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.08px;
  font-weight: 500;
}
.app-section .blue-box .app-buttons-div div button {
  border-radius: 46px;
  padding: 13px 45px;
  font-size: 15px;
  border: 1px solid #fff;
  background-color: #4b9dd8;
  color: white;
  font-family: Filson Pro;
  letter-spacing: -0.73px;
  font-weight: 600;
}

.why-section-content {
  font-family: Filson Pro;
  color: #000;
}
.why-section-content h3 {
  font-size: 3rem;
  line-height: 70px;
  font-weight: 600;
  letter-spacing: -2px;
}
.why-section-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.078px;
}

@media (max-width: 576px) {
  .app-section .blue-box .app-content h2 {
    font-size: 2.2rem;
    letter-spacing: -2px;
    font-weight: 600;
    line-height: 40px;
  }
  .app-section .blue-box .app-content p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.08px;
    font-weight: 500;
    width: 100%;
  }
  .app-section .blue-box .app-buttons-div div button {
    border-radius: 42px;
    padding: 13px 35px;
    font-size: 14px;
    min-width: 150px;
    letter-spacing: -0.73px;
    font-weight: 600;
  }
  .why-section-content h3 {
    font-size: 2.5rem;
    line-height: 50px;
    font-weight: 600;
    letter-spacing: -2px;
  }
  .why-section-content p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.078px;
  }
}
.feature-video {
  width: 85%;
  height: 350px;
}

@media (max-width: 600px) {
  .feature-video {
    width: 95%;
    height: 315px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .app-section .blue-box {
    border-radius: 8px;
  }
  .app-section .blue-box .app-content h2 {
    font-size: 3rem;
    letter-spacing: -2px;
    line-height: 50px;
  }
  .app-section .blue-box .app-buttons-div div button {
    border-radius: 36px;
    padding: 13px 25px;
    font-size: 13px;
  }
}
.exam-pass {
  background-color: rgba(46, 123, 179, 0.2);
  width: 100vw;
  position: relative;
  padding: 90px auto;
}
.exam-pass-content {
  font-family: Filson Pro;
  color: #000;
}
.exam-pass-content h3 {
  font-size: 3rem;
  line-height: 60px;
  letter-spacing: -2px;
  font-weight: 600;
}
.exam-pass-content h5 {
  font-size: 1.2rem;
  line-height: 24px;
  letter-spacing: -0.6px;
  font-weight: 600;
}
.exam-pass-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.2px;
}
.exam-pass-phone-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.exam-pass-phone-img-wrapper img {
  max-height: 70vh;
}

@media (max-width: 1400px) {
  .exam-pass {
    padding: 90px auto;
  }
  .exam-pass-phone-img-wrapper img {
    max-height: 85vh;
  }
}
@media (max-width: 576px) {
  .exam-pass {
    background-color: rgba(46, 123, 179, 0.2);
  }
  .exam-pass-content {
    font-family: Filson Pro;
    color: #000;
  }
  .exam-pass-content h3 {
    font-size: 2.5rem;
    line-height: 50px;
    letter-spacing: -2px;
    font-weight: 600;
  }
  .exam-pass-content h5 {
    font-size: 1.2rem;
    line-height: 24px;
    letter-spacing: -0.6px;
    font-weight: 600;
  }
  .exam-pass-content p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }
  .exam-pass-phone-img-wrapper img {
    max-height: 30vh;
  }
}
.exam-pass.active {
  position: sticky;
  top: 100px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .exam-pass {
    background-color: rgba(46, 123, 179, 0.2);
    width: 100vw;
    position: relative;
    padding: 90px auto;
  }
  .exam-pass-content {
    font-family: Filson Pro;
    color: #000;
  }
  .exam-pass-content h3 {
    font-size: 2.5rem;
    line-height: 50px;
    letter-spacing: -2px;
    font-weight: 600;
  }
  .exam-pass-content h5 {
    font-size: 1.2rem;
    line-height: 24px;
    letter-spacing: -0.6px;
    font-weight: 600;
  }
  .exam-pass-content p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }
  .exam-pass-phone-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .exam-pass-phone-img-wrapper img {
    max-height: 60vh;
  }
}
.testimonials {
  position: relative;
  font-family: Filson Pro;
  min-height: 30vh;
}
.testimonials .bubble {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
}
.testimonials .purple-bubble {
  color: #7769e2;
  background-color: #7769e2;
  right: 30%;
  top: 20%;
}
.testimonials .yellow-bubble {
  color: #ffce31;
  background-color: #ffce31;
  right: 10%;
  top: 10%;
}
.testimonials .lemon-bubble {
  color: #02c869;
  background-color: #02c869;
  right: 15%;
  top: 30%;
}
.testimonials .blue-bubble {
  color: #4b9dd8;
  background-color: #4b9dd8;
  right: 20%;
  top: 15%;
}
.testimonials-header h3 {
  font-size: 2.25rem;
  font-weight: 600;
  letter-spacing: -3px;
  line-height: 44px;
}
.testimonials-card {
  max-width: 350px;
  padding: 18px;
  margin: 20px;
  width: 300.57px;
  height: 192.06px;
  background: #FFFFFF;
  box-shadow: 0px 2.69284px 23.5624px rgba(0, 0, 0, 0.08);
  border-radius: 16.8303px;
}
.testimonials-card-header {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
}
.testimonials-card-header img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.testimonials-card-header h6 {
  color: #112957;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14.6237px;
  line-height: 18px;
  color: #112957;
  opacity: 0.9;
  margin-bottom: 0;
}
.testimonials-card-header p {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12.5346px;
  line-height: 18px;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 0;
}
.testimonials-card-body p {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.7586px;
  line-height: 19px;
  color: #000000;
}

@media (max-width: 576px) {
  .testimonials {
    min-height: 60vh;
  }
  .testimonials-header h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 38px;
  }
}
.news {
  font-family: Filson Pro;
}
.news-header h3 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -3px;
}

@media (max-width: 576px) {
  .news {
    min-height: 60vh;
  }
  .news-header h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 38px;
  }
}
.contact-section {
  font-family: Filson Pro;
  min-height: 40vh;
}
.contact-section-header {
  position: relative;
}
.contact-section-header h3 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -3px;
}
.contact-section-info {
  min-height: 40vh;
  background-image: linear-gradient(0deg, rgba(119, 105, 226, 0.7), rgba(119, 105, 226, 0.7)), url("../images/contact-boys.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
.contact-section-info p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 33.57px;
  letter-spacing: -0.6px;
}
.contact-section-info img {
  width: 27px;
}
.contact-section-form form input,
.contact-section-form form textarea {
  padding: 25px 18px;
  width: 90%;
  border-radius: 8px;
  background-color: rgba(237, 237, 237, 0.7);
  font-size: 16px;
  letter-spacing: -0.8px;
  outline: none;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28.4px;
  font-weight: 500;
  border: 1px solid #f5f5f5;
}

@media (min-width: 1400px) {
  .contact-section {
    font-family: Filson Pro;
    min-height: 40vh;
  }
  .contact-section-header {
    position: relative;
  }
  .contact-section-header h3 {
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -3px;
  }
  .contact-section-info {
    min-height: 40vh;
    background-image: linear-gradient(0deg, rgba(119, 105, 226, 0.7), rgba(119, 105, 226, 0.7)), url("../images/contact-boys.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
  }
  .contact-section-info p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 33.57px;
    letter-spacing: -0.8px;
  }
  .contact-section-info img {
    width: 27px;
  }
  .contact-section-form form input,
  .contact-section-form form textarea {
    padding: 37px 21px;
    width: 90%;
    border-radius: 8px;
    background-color: rgba(237, 237, 237, 0.7);
    font-size: 16px;
    letter-spacing: -0.8px;
    outline: none;
    color: rgba(0, 0, 0, 0.45);
    line-height: 33.4px;
    font-weight: 500;
    border: 1px solid #f5f5f5;
  }
}
@media (max-width: 576px) {
  .contact-section {
    min-height: 80vh;
  }
  .contact-section-header h3 {
    font-size: 2.2rem;
    line-height: 38px;
  }
  .contact-section-info {
    min-height: 50vh;
  }
  .contact-section-info p {
    font-size: 16px;
    line-height: 33.57px;
    letter-spacing: -0.8px;
  }
  .contact-section-info img {
    width: 20px;
  }
  .contact-section-form form input,
  .contact-section-form form textarea {
    width: 100%;
    padding: 15px 15px;
  }
}
@media (max-width: 991px) {
  .contact-section-form form input,
  .contact-section-form form textarea {
    width: 100%;
  }
}
.footer {
  border-top: 2px solid #eee;
}
.footer p.copyright {
  font-size: 12px;
  line-height: 26.32px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}
.footer-content p.info {
  font-size: 13px;
  line-height: 25px;
  font-weight: 400;
  font-family: Filson Pro;
}
.footer-content p.number {
  font-size: 13px;
  font-family: "Nunito", sans-serif;
  line-height: 16.54px;
  font-weight: 600;
}
.footer-content h6 {
  font-size: 14px;
  line-height: 28px;
  font-weight: 800;
  font-family: "Nunito", sans-serif;
}
.footer-links {
  font-family: "Nunito", sans-serif;
}
.footer-links h6 {
  font-size: 14px;
  line-height: 28px;
  font-weight: 900;
  font-family: "Nunito", sans-serif;
}
.footer-links ul {
  padding-left: 0;
  list-style-type: none;
}
.footer-links li a {
  text-decoration: none;
  color: #000;
  font-size: 12px;
  line-height: 28px;
}

.about-header {
  min-height: 85vh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661276319/a1tutor/santi-vedri-O5EMzfdxedg-unsplash_myuv2r.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.about-header-content {
  font-family: Filson Pro;
}
.about-header-content h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -2px;
  color: #fff;
}
.about-header-content p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.08px;
  font-weight: 500;
  color: #fff;
}

.about-header-section {
  min-height: 70vh;
  font-family: Filson Pro;
  position: relative;
}
.about-header-section .about-header-main-title {
  font-size: 5rem;
  font-weight: 600;
  color: #112957;
  letter-spacing: -2px;
  line-height: 6rem;
}
.about-header-section h6 {
  font-size: 20px;
  color: #112957;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
  line-height: 35px;
}
.about-header-section .img-two {
  width: 250px;
  position: absolute;
  top: -5%;
  right: 5%;
  animation: circular 10s infinite 3s ease-in-out;
}
.about-header-section .img-three {
  width: 250px;
  position: absolute;
  bottom: -5%;
  right: 5%;
  rotate: -30deg;
  animation: moveUpandDown 5s infinite 3s ease-in-out;
}
.about-header-section .book {
  width: 140px;
  position: absolute;
  top: 20%;
  right: 15%;
  rotate: 30deg;
  animation: scaleUpandDown 5s infinite 3s ease-in-out;
}

.about-us {
  font-family: Filson Pro;
}
.about-us-header h3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -2px;
}
.about-us-content p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-weight: 400;
  color: #0e0e0e;
}

.value__section {
  font-family: Filson Pro;
}
.value__section-header h3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -3px;
  color: #112957;
}
.value__section .value-box {
  background-color: #4b9dd8;
  color: #fff;
  padding: 25px;
  display: flex;
  align-items: center;
  min-height: 250px;
  border-radius: 20px;
  font-family: Filson Pro;
}
.value__section .value-box h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: -1.5px;
}
.value__section .value-box h3 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 600;
  letter-spacing: -1.5px;
}
.value__section .value-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0784237px;
}
.value__section .value-box.one {
  background-color: #4b9dd8;
}
.value__section .value-box.two {
  background-color: #eb5757;
}
.value__section .value-box.three {
  background-color: #7769e2;
}
.value__section .value-box.four {
  background-color: #00b25d;
}
.value__section .value-box.five {
  background-color: #4b9dd8;
}
.value__section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0784237px;
}

.value-section {
  overflow-x: hidden;
  background-color: #f5f5f5;
}
.value-section-content {
  font-family: Filson Pro;
}
.value-section-content-header h3 {
  font-weight: 600;
  font-size: 44px;
  line-height: 60px;
  letter-spacing: -2px;
}
.value-section-content h5 {
  font-size: 1.2rem;
  line-height: 24px;
  letter-spacing: -0.6px;
  font-weight: 600;
}
.value-section-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.curriculum-section {
  overflow-x: hidden;
  background-color: #f5f5f5;
}
.curriculum-section-content {
  font-family: Filson Pro;
}
.curriculum-section-content-header h3 {
  font-weight: 600;
  font-size: 44px;
  line-height: 60px;
  letter-spacing: -2px;
}
.curriculum-section-content h5 {
  font-size: 33.7052px;
  line-height: 24px;
  letter-spacing: -1px;
  font-weight: 600;
}
.curriculum-section-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.2px;
}
.curriculum-section-content ul {
  margin-left: 0;
}
.curriculum-section-content ul li {
  letter-spacing: -1px;
}

@media (max-width: 767px) {
  .about-header-section .about-header-main-title {
    font-size: 4rem;
    line-height: 4.3rem;
  }
  .about-header-section .img-two {
    width: 150px;
    top: 0%;
  }
}
@media (max-width: 576px) {
  .about-header {
    min-height: 95vh;
  }
  .about-header-content h1 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -1.5px;
    color: #fff;
  }
  .about-header-content p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02px;
    font-weight: 500;
    color: #fff;
  }
  .about-header-section {
    min-height: 70vh;
    font-family: Filson Pro;
    position: relative;
  }
  .about-header-section .about-header-main-title {
    font-size: 2.6rem;
    font-weight: 600;
    color: #112957;
    letter-spacing: -1px;
    line-height: 3rem;
  }
  .about-header-section h6 {
    font-size: 17px;
    color: #112957;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
    line-height: 25px;
  }
  .about-header-section .img-two {
    top: -3%;
    right: -5%;
  }
  .about-us-header h3 {
    font-size: 2.5rem;
    line-height: 50px;
  }
  .about-us-content p {
    font-size: 15px;
    line-height: 24px;
  }
  .value-section-content-header h3 {
    font-size: 2.5rem;
    line-height: 50px;
    letter-spacing: -2px;
  }
  .value-section-content h5 {
    font-size: 1.2rem;
    line-height: 24px;
    letter-spacing: -0.6px;
    font-weight: 600;
  }
  .value-section-content p {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }
  .curriculum-section-content-header h3 {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 50px;
    letter-spacing: -2px;
  }
  .curriculum-section-content h5 {
    font-size: 1.5rem;
  }
  .curriculum-section-content p {
    font-size: 15px;
    letter-spacing: -0.2px;
  }
}
@media (max-width: 340px) {
  .about-header {
    min-height: 120vh;
  }
  .about-header-content h1 {
    font-size: 2.5rem;
  }
}
@keyframes circular {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes moveUpandDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes scaleUpandDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.service-header {
  min-height: 85vh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(../images/service.jpg);
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.service-header-content {
  font-family: Filson Pro;
}
.service-header-content h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -2px;
  color: #fff;
}
.service-header-content p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02px;
  font-weight: 500;
  color: #fff;
}

.service-info h6 {
  font-family: Filson Pro;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.01px;
  line-height: 24px;
}

@media (max-width: 576px) {
  .service-header {
    min-height: 100vh;
  }
  .service-header-content h1 {
    font-size: 3rem;
    line-height: 50px;
    letter-spacing: -2px;
  }
  .service-header-content p {
    font-size: 14px;
    line-height: 20px;
  }
  .service-info h6 {
    font-size: 15px;
  }
}
@media (max-width: 340px) {
  .service-header {
    min-height: 120vh;
  }
  .service-header-content h1 {
    font-size: 2.5rem;
  }
}
.pricing-header {
  min-height: 85vh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url("../images/pricing.jpeg");
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pricing-header-content {
  font-family: Filson Pro;
}
.pricing-header-content h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -2px;
  color: #fff;
}
.pricing-header-content p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02px;
  font-weight: 500;
  color: #fff;
}

.invest {
  min-height: 90vh;
  background-image: url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661309654/a1tutor/rafiki_wjccfb.png);
  background-size: 60%;
  background-position: 100% 70%;
  background-repeat: no-repeat;
}
.invest-header {
  font-family: Filson Pro;
}
.invest-header h3 {
  font-size: 3rem;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: -2px;
}
.invest-header p {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: #555555;
}
.invest-content .box {
  border-radius: 4px;
  color: white;
  font-family: Filson Pro;
}
.invest-content .box .price-header {
  min-height: 150px;
}
.invest-content .box .price-header h5 {
  font-size: 23.2332px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.invest-content .box .price-header h6.slashed-price {
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
  letter-spacing: -0.03em;
  position: relative;
}
.invest-content .box .price-header h6.slashed-price::after {
  content: url(../images/slashed-red.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.invest-content .box .price-header h4 {
  font-size: 2.25rem;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: -0.03em;
}
.invest-content .box .price-header h4 span.month {
  font-size: 1.5rem;
  line-height: 29px;
  letter-spacing: -0.05em;
  font-weight: 500;
  font-family: Filson Pro;
}
.invest-content .box .price-body-item {
  border-top: 1px solid #fff;
  display: flex;
  gap: 5px;
}
.invest-content .box .price-body-item p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 500;
  margin-bottom: 0;
}
.invest-content .box.one {
  background-color: #7769e2;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.invest-content .box.two {
  background-color: #02c869;
}
.invest-content .box.three {
  background-color: #ffce31;
}

@media (min-width: 992px) and (max-width: 1115px) {
  .invest-content .box {
    border-radius: 4px;
  }
  .invest-content .box .price-header h5 {
    font-size: 18.2332px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: -0.05em;
  }
  .invest-content .box .price-header h6.slashed-price {
    font-size: 25px;
    line-height: 32px;
  }
  .invest-content .box .price-header h4 {
    font-size: 2rem;
    line-height: 40px;
  }
  .invest-content .box .price-header h4 span.month {
    font-size: 1.2rem;
    line-height: 25px;
  }
}
@media (max-width: 576px) {
  .pricing-header {
    min-height: 100vh;
  }
  .pricing-header-content {
    font-family: Filson Pro;
  }
  .pricing-header-content h1 {
    font-size: 3rem;
    line-height: 50px;
    letter-spacing: -4px;
  }
  .pricing-header-content p {
    font-size: 15px;
  }
  .invest {
    min-height: 90vh;
    background-size: cover;
    background-position: center;
  }
  .invest-header h3 {
    font-size: 2.5rem;
    line-height: 60px;
    letter-spacing: -4px;
  }
  .invest-header p {
    font-size: 16px;
    line-height: 24px;
  }
  .invest-content .box .price-header {
    min-height: auto;
    padding: 20px auto;
  }
  .invest-content .box .price-header h5 {
    font-size: 18.2332px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: -0.05em;
  }
  .invest-content .box .price-header h6.slashed-price {
    font-size: 25px;
    line-height: 32px;
  }
  .invest-content .box .price-header h4 {
    font-size: 2rem;
    line-height: 40px;
  }
  .invest-content .box .price-header h4 span.month {
    font-size: 1.2rem;
    line-height: 25px;
  }
}
@media (max-width: 340px) {
  .pricing-header {
    min-height: 120vh;
  }
  .pricing-header-content h1 {
    font-size: 2.5rem;
  }
  .pricing-header-content p {
    font-size: 15px;
  }
  .invest {
    min-height: 90vh;
    background-size: cover;
    background-position: center;
  }
  .invest-header h3 {
    font-size: 2.5rem;
    line-height: 40px;
    letter-spacing: -4px;
  }
  .invest-header p {
    font-size: 15px;
    line-height: 24px;
  }
  .invest-content .box {
    border-radius: 4px;
  }
  .invest-content .box .price-header {
    min-height: auto;
    padding: auto auto 20px;
  }
  .invest-content .box .price-header h5 {
    font-size: 20px;
    line-height: 24px;
  }
  .invest-content .box .price-header h6.slashed-price {
    font-size: 24px;
    line-height: 28px;
  }
  .invest-content .box .price-header h4 {
    font-size: 2rem;
    line-height: 38px;
    font-weight: 600;
    letter-spacing: -0.03em;
  }
  .invest-content .box .price-header h4 span.month {
    font-size: 1.2rem;
    line-height: 24px;
  }
  .invest-content .box .price-body-item p {
    font-size: 14px;
    line-height: 22px;
  }
}
.team {
  font-family: Filson Pro;
  text-align: center;
}
.team-header h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -2px;
  color: #112957;
}
.team h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0784237px;
  color: #0e0e0e;
  font-weight: 600;
}
.team p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0784237px;
  color: #0e0e0e;
}

.faq-header {
  min-height: 70vh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url("../images/Faq.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.faq-header-content {
  font-family: Filson Pro;
}
.faq-header-content h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -2px;
  color: #fff;
}
.faq-header-content .search-section p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02px;
  font-weight: 500;
  color: #fff;
}
.faq-header-content .search-section .box {
  position: relative;
}
.faq-header-content .search-section .box input {
  width: 80%;
  padding: 13px 10px 13px 38px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.1);
  border-radius: 60px;
  outline: none;
  caret-color: #fff;
}
.faq-header-content .search-section .box img {
  position: absolute;
  left: 80px;
  top: 13px;
}

.faq {
  min-height: 50vh;
  overflow-x: hidden;
  font-family: Filson Pro;
}
.faq .accordion-button::after {
  transform: scale(0.7) !important;
  color: #4b9dd8;
}
.faq .accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus.svg);
  background-size: 20px;
}
.faq .accordion-button:not(.collapsed) {
  color: #fff;
  background: #4b9dd8 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
}
.faq .accordion-body {
  color: #fff;
  background: #4b9dd8 !important;
}
.faq .accordion-body p {
  color: #fff;
  font-size: 16px;
}
.faq .accordion-button {
  border: 1px solid #4b9dd8;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
  color: #000000;
  outline: none;
}
.faq .accordion-button:focus {
  box-shadow: none;
}
.faq .accordion-item {
  border: none;
}

@media (max-width: 576px) {
  .faq-header {
    min-height: 90vh;
  }
  .faq-header-content {
    font-family: Filson Pro;
  }
  .faq-header-content h1 {
    font-size: 3rem;
    line-height: 50px;
  }
  .faq-header-content .search-section p {
    font-size: 15px;
    line-height: 24px;
  }
  .faq-header-content .search-section .box input {
    width: 100%;
  }
  .faq {
    min-height: 50vh;
  }
  .faq .accordion-button:not(.collapsed) {
    font-size: 16px;
    line-height: 25px;
  }
  .faq .accordion-body {
    color: #fff;
    background: #4b9dd8 !important;
  }
  .faq .accordion-body p {
    color: #fff;
    font-size: 13px;
  }
  .faq .accordion-button {
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
  }
}
@media (max-width: 340px) {
  .faq-header {
    min-height: 100vh;
  }
  .faq-header-content h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }
  .faq-header-content .search-section p {
    font-size: 14px;
  }
}
.register {
  overflow-x: hidden;
}
.register-content {
  min-height: 100vh;
  background-color: #4b9dd8;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Filson Pro;
}
.register-content h1 {
  font-weight: 600;
  font-size: 45.1829px;
  line-height: 56px;
  letter-spacing: -4.03419px;
  color: #fff;
}
.register-content h1 span {
  color: #e4e0ff;
}
.register-content h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -1px;
}
.register-content-img {
  position: relative;
  display: grid;
  place-content: center;
}
.register-content-img img {
  z-index: 100;
}
.register-content-img::before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  width: 40%;
  height: 40%;
  top: 30%;
  left: 30%;
  border-radius: 50%;
  animation: rippleD 2.5s ease-out infinite;
}
.register-content-img::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 40%;
  height: 40%;
  top: 30%;
  left: 30%;
  border-radius: 50%;
  animation: ripple 1.5s ease-out infinite;
}
.register-form {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Filson Pro;
}
.register-form-card {
  background: #FFFFFF;
  box-shadow: 5px 24px 40px 12px rgba(58, 157, 226, 0.04);
  border-radius: 16px;
}
.register-form-card-header h3 {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.05em;
  line-height: 35.52px;
}
.register-form-card-header h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4B5667;
}
.register-form-card form input {
  padding: 22px 10px;
  border-radius: 4px;
  width: 100%;
  background-color: rgba(237, 237, 237, 0.7);
  font-size: 14px;
  letter-spacing: -1px;
  outline: none;
  color: rgba(0, 0, 0, 0.45);
  line-height: 16px;
  font-weight: 500;
  border: 1px solid #f5f5f5;
}
.register-form-card form button {
  padding: 16px 32px;
  min-width: 176px;
  min-height: 48px;
  background: rgba(58, 157, 226, 0.1);
  border: 1px solid #3A9DE2;
  border-radius: 80px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -1px;
  color: #3A9DE2;
}

@media (max-width: 576px) {
  .register-content h1 {
    font-size: 2.5rem;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .register-content h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}
@keyframes rippleD {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
.bubble-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: -1;
}
.bubble-section img,
.bubble-section div {
  width: 30px;
  height: 30px;
}
.bubble-section .x {
  animation: x 30s linear infinite alternate;
}
.bubble-section .y {
  animation: y 7s linear infinite alternate;
}
.bubble-section .a {
  animation: a 15s linear infinite alternate;
}
.bubble-section .b {
  animation: b 12s linear infinite alternate;
}
.bubble-section .c {
  animation: a 20s linear infinite alternate;
}
.bubble-section .d {
  animation: b 22s linear infinite alternate;
}
.bubble-section .e {
  animation: a 12s linear infinite alternate;
}
.bubble-section .f {
  animation: b 15s linear infinite alternate;
}
@keyframes x {
  100% {
    transform: translateX(calc(100vw - 30px));
  }
}
@keyframes a {
  100% {
    transform: translateX(calc(100vw - 30px));
  }
}
@keyframes y {
  100% {
    transform: translateY(calc(80vh - 30px));
  }
}
@keyframes b {
  100% {
    transform: translateY(calc(80vh - 30px));
  }
}

.privacy-policy-content {
  min-height: 50vh;
  padding: 5rem 0;
  font-family: Filson Pro;
}
.privacy-policy-content-head {
  margin-bottom: 40px;
  min-height: 40vh;
  text-align: center;
}
.privacy-policy-content-head h1 {
  font-weight: 700;
  font-size: 6rem;
}
.privacy-policy-content-head p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
  color: #0e0e0e;
}
.privacy-policy-content-body h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: -1px;
}
.privacy-policy-content-body p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
  color: #0e0e0e;
}

@media (max-width: 767px) {
  .privacy-policy-content {
    min-height: 50vh;
    padding: 5rem 0;
    font-family: Filson Pro;
  }
  .privacy-policy-content-head {
    margin-bottom: 30px;
    min-height: 30vh;
    text-align: center;
  }
  .privacy-policy-content-head h1 {
    font-weight: 700;
    font-size: 4rem;
  }
  .privacy-policy-content-head p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    font-weight: 400;
    color: #0e0e0e;
  }
  .privacy-policy-content-body h3 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -2px;
  }
  .privacy-policy-content-body p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.078px;
  }
}
.blog-content {
  min-height: 50vh;
  padding: 5rem 0;
  font-family: Filson Pro;
}
.blog-content-head {
  margin-bottom: 40px;
  min-height: 40vh;
  text-align: center;
}
.blog-content-head h1 {
  font-weight: 700;
  font-size: 6rem;
}
.blog-content-head p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
  color: #0e0e0e;
}
.blog-content-body h3 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -1px;
}
.blog-content-body p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
  color: #0e0e0e;
}

.single-blog {
  min-height: 50vh;
  padding: 3rem 0;
  font-family: Filson Pro;
}
.single-blog-head .blog-title {
  font-size: 2.6rem !important;
  color: #0e0e0e;
}
.single-blog-head .banner-img {
  width: 100%;
  height: auto;
  border: 2px solid #bbb;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .blog-content {
    min-height: 50vh;
    padding: 3em 0;
    font-family: Filson Pro;
  }
  .blog-content-head {
    margin-bottom: 40px;
    min-height: 40vh;
    text-align: center;
  }
  .blog-content-head h1 {
    font-weight: 700;
    font-size: 6rem;
  }
  .blog-content-head p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    font-weight: 400;
    color: #0e0e0e;
  }
  .blog-content-body h3 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -1px;
  }
  .blog-content-body p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    font-weight: 400;
    color: #0e0e0e;
  }
}
@media (max-width: 576px) {
  .blog-content-head {
    min-height: 20vh;
  }
  .blog-content-head h1 {
    font-size: 4rem;
  }
  .blog-content-body h3 {
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-top: 5px;
  }
  .blog-content-body p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.078px;
  }
  .single-blog-head .blog-title {
    font-size: 1.4rem;
  }
  .single-blog a {
    color: #000;
  }
  .single-blog-body h1,
  .single-blog-body h2,
  .single-blog-body h3,
  .single-blog-body h4,
  .single-blog-body h5,
  .single-blog-body h6 {
    font-size: 18px;
  }
  .single-blog-body p {
    font-size: 14px;
  }
}
.blog-loader {
  display: grid;
  place-content: center;
}
.blog-loader .spinner {
  position: relative;
  width: 16px;
  height: 16px;
}
.blog-loader .spinner div {
  width: 100%;
  height: 100%;
  background-color: #004dff;
  border-radius: 50%;
  animation: spinner-4t3wzl 1.25s infinite backwards;
}
.blog-loader .spinner div:nth-child(1) {
  animation-delay: 0.15s;
  background-color: rgba(0, 77, 255, 0.9);
}
.blog-loader .spinner div:nth-child(2) {
  animation-delay: 0.3s;
  background-color: rgba(0, 77, 255, 0.8);
}
.blog-loader .spinner div:nth-child(3) {
  animation-delay: 0.45s;
  background-color: rgba(0, 77, 255, 0.7);
}
.blog-loader .spinner div:nth-child(4) {
  animation-delay: 0.6s;
  background-color: rgba(0, 77, 255, 0.6);
}
.blog-loader .spinner div:nth-child(5) {
  animation-delay: 0.75s;
  background-color: rgba(0, 77, 255, 0.5);
}

@keyframes spinner-4t3wzl {
  0% {
    transform: rotate(0deg) translateY(-200%);
  }
  60%, 100% {
    transform: rotate(360deg) translateY(-200%);
  }
}
.dashboard {
  width: 100vw;
  display: flex;
  font-family: Filson Pro;
}
.dashboard-side-nav {
  width: 245px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f9fafb;
  height: 100vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
}
.dashboard-side-nav_logo_box {
  padding: 5px 42px;
  display: flex;
  justify-content: space-between;
}
.dashboard-side-nav_nav_list {
  margin-top: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-side-nav_nav_list ul {
  padding-left: 0;
  list-style-type: none;
}
.dashboard-side-nav_nav_list ul li p {
  cursor: pointer;
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  width: 100%;
  padding: 15px 42px;
  position: relative;
  color: rgba(200, 205, 214, 0.5);
  margin-bottom: 0;
}
.dashboard-side-nav_nav_list ul li p .list__icon svg path {
  stroke: rgba(200, 205, 214, 0.5019607843);
}
.dashboard-side-nav_nav_list ul li p .list__text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  position: relative;
}
.dashboard-side-nav_nav_list ul li p .red-info {
  position: absolute;
  top: 30%;
  right: 30%;
  background-color: #ff006e;
  color: #fff;
  padding: 0px 2px;
  font-size: 10px;
  border-radius: 3px;
  font-weight: 600;
  font-style: italic;
}
.dashboard-side-nav_nav_list ul li a {
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  width: 100%;
  padding: 15px 42px;
  position: relative;
  color: #c8cdd6;
}
.dashboard-side-nav_nav_list ul li a .list__icon svg path {
  stroke: #bdbdbd;
}
.dashboard-side-nav_nav_list ul li a .list__text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.dashboard-side-nav_nav_list ul li a:hover {
  color: #2d9cdb;
  padding: 15px 42px 15px 46px;
}
.dashboard-side-nav_nav_list ul li a:hover .list__icon svg path {
  stroke: #2d9cdb;
  stroke-width: 1;
}
.dashboard-side-nav_nav_list ul li a:hover .list__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.dashboard-side-nav_nav_list ul li a.active {
  color: #2d9cdb;
}
.dashboard-side-nav_nav_list ul li a.active::after {
  content: "";
  position: absolute;
  top: calc(50% - 18px);
  left: 0;
  height: 36px;
  width: 5px;
  background-color: #2d9cdb;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dashboard-side-nav_nav_list ul li a.active .list__icon svg path {
  stroke: #2d9cdb;
  stroke-width: 2;
}
.dashboard-side-nav_nav_list ul li a.active .list__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.dashboard-side-nav_nav_list .logout__group {
  margin: 0px 30px;
}
.dashboard-side-nav_nav_list .logout__group a {
  text-decoration: none;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #c8cdd6;
}
.dashboard-side-nav_nav_list .logout__group button {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #c8cdd6;
  outline: none;
  border: none;
  width: 100%;
}
.dashboard-main-content-layout {
  width: calc(100% - 245px);
  background-color: #f5f5f5;
  margin-left: 245px;
  min-height: 100vh;
}
.dashboard-main-content-layout .top-section {
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease-in;
}
.dashboard-main-content-layout .top-section-info {
  text-decoration: none;
  color: #05182d;
}
.dashboard-main-content-layout .top-section-info:hover {
  color: #05182d;
}
.dashboard-main-content-layout .top-section .user-img {
  width: 45px;
}
.dashboard-main-content-layout .top-section .user-fullname {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #615e83;
  letter-spacing: -1px;
  margin-bottom: 0;
}
.dashboard-main-content-layout .top-section .user-location {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #c8cdd6;
  margin-bottom: 0;
}
.dashboard-main-content-layout .top-section.active {
  padding: 20px 40px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: saturate(100%) blur(2px);
          backdrop-filter: saturate(100%) blur(2px);
}
.dashboard-main-content-layout .dashboard-main-content {
  padding: 40px;
}

.dashboard-search {
  position: relative;
}
.dashboard-search-input {
  background: #fafafa;
  width: 100%;
  height: 50px;
  border: 2px solid #f9fafb;
  border-radius: 112.791px;
  padding: 4px 40px 4px 16px;
  outline: 2px solid #f9fafb;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.dashboard-search-input:focus {
  outline: 2px solid #ddd;
}
.dashboard-search img {
  position: absolute;
  top: 30%;
  right: 15px;
}

@media (max-width: 991px) {
  .dashboard {
    width: 100vw;
    display: flex;
    font-family: Filson Pro;
  }
  .dashboard-side-nav {
    width: 100vw;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    background-color: #fff;
    height: 100vh;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    z-index: 100000;
    transition: all 0.3s ease-in-out;
  }
  .dashboard-side-nav_logo_box {
    padding: 5px 42px;
  }
  .dashboard-side-nav_nav_list {
    margin-top: 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .dashboard-side-nav_nav_list ul {
    padding-left: 0;
    list-style-type: none;
  }
  .dashboard-side-nav_nav_list ul li p {
    display: flex;
    transition: all 0.2s ease-in-out;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: 100%;
    padding: 15px 42px;
    position: relative;
    color: rgba(200, 205, 214, 0.5);
    margin-bottom: 0;
  }
  .dashboard-side-nav_nav_list ul li p .list__icon svg path {
    stroke: rgba(200, 205, 214, 0.5019607843);
  }
  .dashboard-side-nav_nav_list ul li p .list__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    position: relative;
  }
  .dashboard-side-nav_nav_list ul li p .red-info {
    position: absolute;
    top: 5px;
    right: 50%;
    background-color: #ff006e;
    color: #fff;
    padding: 3px;
    font-size: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-style: italic;
  }
  .dashboard-side-nav_nav_list ul li a {
    display: flex;
    transition: all 0.2s ease-in-out;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: 100%;
    padding: 15px 42px;
    position: relative;
    color: #c8cdd6;
  }
  .dashboard-side-nav_nav_list ul li a .list__icon svg path {
    stroke: #bdbdbd;
  }
  .dashboard-side-nav_nav_list ul li a .list__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .dashboard-side-nav_nav_list ul li a:hover {
    color: #2d9cdb;
    padding: 15px 42px 15px 46px;
  }
  .dashboard-side-nav_nav_list ul li a:hover .list__icon svg path {
    stroke: #2d9cdb;
    stroke-width: 1;
  }
  .dashboard-side-nav_nav_list ul li a:hover .list__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  .dashboard-side-nav_nav_list ul li a.active {
    color: #2d9cdb;
    padding: 15px 42px 15px 50px;
  }
  .dashboard-side-nav_nav_list ul li a.active::after {
    content: "";
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    height: 36px;
    width: 5px;
    background-color: #2d9cdb;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .dashboard-side-nav_nav_list ul li a.active .list__icon svg path {
    stroke: #2d9cdb;
    stroke-width: 2;
  }
  .dashboard-side-nav_nav_list ul li a.active .list__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  .dashboard-side-nav_nav_list .logout__group {
    margin: 0px 30px;
  }
  .dashboard-side-nav_nav_list .logout__group a {
    text-decoration: none;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #c8cdd6;
  }
  .dashboard-side-nav.mobile-active {
    left: 0;
  }
  .dashboard-main-content-layout {
    width: 100%;
    margin-left: 0;
  }
  .dashboard-main-content-layout .top-section.active {
    padding: 20px;
  }
  .dashboard-main-content-layout .dashboard-main-content {
    padding: 20px;
  }
}
.top-title-bar {
  margin-bottom: 30px;
}
.top-title-bar h3 {
  font-size: 24px;
  letter-spacing: -1px;
  color: #615e83;
  font-weight: 700;
}

.subcription-cards .box {
  border-radius: 4px;
  color: white;
  font-family: Filson Pro;
}
.subcription-cards .box .price-header h5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.subcription-cards .box .price-header h6.slashed-price {
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: -0.03em;
  position: relative;
}
.subcription-cards .box .price-header h6.slashed-price::after {
  content: url(../images/slashed-red.png);
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
}
.subcription-cards .box .price-header h4 {
  font-size: 2rem;
  line-height: 38px;
  font-weight: 600;
  letter-spacing: -0.03em;
}
.subcription-cards .box .price-header h4 span.month {
  font-size: 1.2rem;
  line-height: 29px;
  letter-spacing: -0.03em;
  font-weight: 500;
}
.subcription-cards .box .price-body-item {
  border-top: 1px solid #fff;
  display: flex;
  gap: 5px;
}
.subcription-cards .box .price-body-item p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 500;
  margin-bottom: 0;
}
.subcription-cards .box.one {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #eb5757;
}
.subcription-cards .box.two {
  background-color: #ffce31;
}
.subcription-cards .box.three {
  background-color: #00b25d;
}

.transaction .transaction-box {
  color: #fff;
  height: 100.87px;
  background: #02c869;
  box-shadow: 0px 14.0495px 77.2723px -21.0743px rgba(43, 37, 37, 0.12);
  border-radius: 11.5278px;
  padding: 20px;
}
.transaction .transaction-box h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 15.3704px;
  color: #f2f2f2;
}
.transaction .transaction-box h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.transaction .transaction-box.one {
  background-color: #4b9dd8;
}
.transaction .transaction-box.two {
  background-color: #ffce31;
}
.transaction .transaction-box.three {
  background-color: #eb5757;
}
.transaction-table-title {
  font-size: 20px;
  color: #615e83;
  font-weight: 600;
  letter-spacing: -0.6px;
}
.transaction-table-box {
  width: 100%;
  overflow-x: auto;
}
.transaction-table-box table {
  background-color: none !important;
  border-collapse: separate;
  border-spacing: 0px 8px !important;
  width: 100%;
  font-size: 12px;
}
.transaction-table-box thead tr {
  background-color: #4b9dd8;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
}
.transaction-table-box tbody tr {
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-size: 14px;
  border-radius: 20px !important;
}
.transaction-table-box tbody tr:hover {
  background-color: #4b9dd8 !important;
  color: #fff !important;
}
.transaction-table-box tbody tr:hover a {
  color: #fff;
}
.transaction-table-box tbody tr td,
.transaction-table-box thead tr th {
  padding: 1rem !important;
  white-space: nowrap;
}
.transaction-table-box thead th {
  text-transform: uppercase;
}

.coupon-box label {
  font-size: 13px;
}
.coupon-box input {
  padding: 10px;
  border: 1px solid white;
  outline: none;
  width: 100%;
  max-width: 300px;
  display: block;
  border-radius: 4px;
  font-size: 14px;
}

.partnership-page {
  font-family: Filson Pro;
}
.partnership-page_header {
  min-height: 500px;
}
.partnership-page_header_first_box {
  color: #112957;
  background-color: #fff;
}
.partnership-page_header_first_box_title {
  font-size: 5rem;
  font-weight: 600;
  color: #4b9dd8;
  letter-spacing: -2px;
  line-height: 6rem;
}
.partnership-page_header_first_box_title span {
  color: #112957;
}
.partnership-page_header_second_box_text_box {
  background-color: #4b9dd8;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.partnership-page_header_second_box_text_box .collaborative-initiative-box h5 {
  padding: 6px 10px;
  background-color: #fff;
  color: #112957;
  font-size: 14px;
  display: inline-block;
  border-radius: 10px;
  text-transform: uppercase;
}
.partnership-page_header_second_box_text_box .collaborative-initiative-box h3 {
  color: #fff;
  font-size: 22px;
}
.partnership-page_header_second_box_text_box .who-can-collaborate {
  margin-top: 50px;
  min-height: 200px;
}
.partnership-page_header_second_box_text_box .who-can-collaborate h3 {
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 3rem;
}
.partnership-page_header_second_box_text_box .who-can-collaborate-box {
  background-color: #fff;
  min-height: 300px;
  border-radius: 10px;
  color: #112957;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  padding: 30px 20px;
}
.partnership-page_header_second_box_text_box .who-can-collaborate-box .content-box h5 {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 2.5rem;
}
.partnership-page_header_second_box_text_box .who-can-collaborate-box .content-box p {
  font-size: 13px;
  margin-bottom: 0;
}
.partnership-page_header_second_box_text_box.active {
  margin: 30px 50px;
  border-radius: 30px;
}
.partnership-page .benefit-of-collaboration {
  font-family: Filson Pro;
}
.partnership-page .benefit-of-collaboration-header h3 {
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 3rem;
  color: #fff;
}
.partnership-page .benefit-of-collaboration-body-content-box {
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
  padding: 40px 0;
}
.partnership-page .benefit-of-collaboration-body-content-box h5 {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 3rem;
  color: #fff;
}
.partnership-page .benefit-of-collaboration-body-content-box p {
  color: #fff;
  font-size: 18px;
  letter-spacing: -0.4px;
}
.partnership-page-contact-section {
  font-family: Filson Pro;
  min-height: 400px;
}
.partnership-page-contact-section-header {
  position: relative;
}
.partnership-page-contact-section-header h3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -3px;
  color: #4b9dd8;
}
.partnership-page-contact-section-info {
  min-height: 40vh;
  background-image: linear-gradient(0deg, rgba(119, 105, 226, 0.7), rgba(119, 105, 226, 0.7)), url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661259768/a1tutor/school-boys_csdfkp.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
.partnership-page-contact-section-info p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 33.57px;
  letter-spacing: -1.5px;
}
.partnership-page-contact-section-info img {
  width: 27px;
}
.partnership-page-contact-section-form form input,
.partnership-page-contact-section-form form textarea {
  padding: 18px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(237, 237, 237, 0.7);
  font-size: 16px;
  letter-spacing: -1px;
  outline: none;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28.4px;
  font-weight: 500;
  border: 1px solid #f5f5f5;
}

@media (max-width: 576px) {
  .partnership-page_header_first_box_title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .partnership-page_header_first_box_title span {
    color: #112957;
  }
  .partnership-page_header_second_box_text_box .collaborative-initiative-box h5 {
    padding: 6px 10px;
    background-color: #fff;
    color: #112957;
    font-size: 12px;
    display: inline-block;
    border-radius: 10px;
    text-transform: uppercase;
  }
  .partnership-page_header_second_box_text_box .collaborative-initiative-box h3 {
    color: #fff;
    font-size: 15px;
    line-height: 16px;
  }
  .partnership-page_header_second_box_text_box .who-can-collaborate {
    margin-top: 50px;
    min-height: 200px;
  }
  .partnership-page_header_second_box_text_box .who-can-collaborate h3 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 2rem;
  }
  .partnership-page_header_second_box_text_box .who-can-collaborate-box {
    min-height: 250px;
  }
  .partnership-page_header_second_box_text_box .who-can-collaborate-box .content-box h5 {
    font-size: 1.8rem;
    letter-spacing: -0.4px;
    line-height: 2.5rem;
  }
  .partnership-page_header_second_box_text_box .who-can-collaborate-box .content-box p {
    font-size: 13px;
    margin-bottom: 0;
  }
  .partnership-page_header_second_box_text_box.active {
    margin: 20px 10px;
    border-radius: 20px;
  }
  .partnership-page .benefit-of-collaboration {
    font-family: Filson Pro;
  }
  .partnership-page .benefit-of-collaboration-header h3 {
    font-size: 2.3rem;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 2.5rem;
  }
  .partnership-page .benefit-of-collaboration-body-content-box {
    padding: 15px 0;
  }
  .partnership-page .benefit-of-collaboration-body-content-box h5 {
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 2rem;
  }
  .partnership-page .benefit-of-collaboration-body-content-box p {
    font-size: 13px;
    letter-spacing: -0.4px;
  }
  .partnership-page-contact-section {
    font-family: Filson Pro;
    min-height: 400px;
  }
  .partnership-page-contact-section-header {
    position: relative;
  }
  .partnership-page-contact-section-header h3 {
    font-size: 2.3rem;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -2px;
    color: #4b9dd8;
  }
  .partnership-page-contact-section-form form input,
  .partnership-page-contact-section-form form textarea {
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(237, 237, 237, 0.7);
    font-size: 14px;
    letter-spacing: 0px;
    outline: none;
    color: rgba(0, 0, 0, 0.45);
    line-height: 28.4px;
    font-weight: 500;
    border: 1px solid #f5f5f5;
  }
}
html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  color: #000;
  width: 100vw;
  overflow-x: hidden;
}

.tx-fraunce {
  font-family: "Fraunces", serif;
}

.main-btn {
  background-color: #4b9dd8;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.73px;
  font-family: Filson Pro;
  min-width: 220px;
}
.main-btn:hover {
  background-color: #4b9dd8;
  color: white;
}

.trans-btn {
  min-width: 210px;
  min-height: 50px;
  background-color: transparent;
  color: #000;
  padding: 13px 35px;
  outline: none;
  border: 0.734177px solid #000000;
  filter: drop-shadow(0px 2.93671px 25.6962px rgba(75, 158, 216, 0.1));
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.734px;
  font-family: Filson Pro;
}
.trans-btn:hover {
  background-color: transparent;
  color: #000;
}

.purple-outline-btn {
  min-width: 220px;
  min-height: 50px;
  background-color: transparent;
  color: #7769e2;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #7769e2;
  filter: drop-shadow(0px 2.93671px 25.6962px rgba(75, 158, 216, 0.1));
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.734px;
  font-family: Filson Pro;
}
.purple-outline-btn:hover {
  background-color: transparent;
  color: #7769e2;
}

.white-btn {
  min-width: 220px;
  background-color: transparent;
  color: #fff;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #fff;
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.734px;
  font-family: Filson Pro;
}
.white-btn:hover {
  background-color: transparent;
  color: #fff;
}

.btn-blue {
  background-color: #4b9dd8;
  color: white;
}
.btn-blue:hover {
  background-color: #4b9dd8;
  color: white;
}

.light-btn {
  background-color: rgba(46, 123, 179, 0.2);
  color: #000;
  border: 1px solid rgba(46, 123, 179, 0.2);
  outline: none;
}
.light-btn:hover {
  background-color: rgba(46, 123, 179, 0.2);
  color: #000;
  border: 1px solid rgba(46, 123, 179, 0.2);
  outline: none;
}

.tx-blue {
  color: #4b9dd8;
}

.tx-green {
  color: #00b25d;
}

.tx-purple {
  color: #7769e2;
}

.tx-orange {
  color: #ff8736;
}

.bg-green {
  background-color: #00b25d;
}

.bg-yellow {
  background-color: #ffce31;
}

.bg-red {
  background-color: #eb5757;
}

.stat-section {
  background-color: #f5f5f5;
  font-family: Filson Pro;
}
.stat-section-content .stat h3 {
  font-size: 44px;
  letter-spacing: -1.95px;
  font-weight: 700;
}
.stat-section-content .stat h6 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
}

.login-btn {
  color: #000;
  padding: 10px;
  min-width: 150px;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  outline: none;
  border-color: #000;
  border-radius: 3px;
  background-color: transparent;
  border-width: 1px;
}

.get-started-btn {
  background-color: #4b9dd8;
  color: #fff;
  padding: 10px;
  min-width: 150px;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  outline: none;
  border: 2px solid #4b9dd8;
  border-radius: 3px;
}

.purple-btn {
  background-color: #7769e2;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.73px;
  font-family: Filson Pro;
  min-width: 220px;
}
.purple-btn:hover {
  background-color: #7769e2;
  color: white;
}

.orange-btn {
  background-color: #ff8736;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.73px;
  font-family: Filson Pro;
  min-width: 220px;
}
.orange-btn:hover {
  background-color: #ff8736;
  color: white;
}

.green-btn {
  background-color: #00b25d;
  min-height: 50px;
  color: white;
  padding: 13px 45px;
  outline: none;
  border: 0.734177px solid #ffffff;
  box-shadow: 0px 2.93671px 25.6962px rgba(75, 158, 216, 0.16);
  border-radius: 46.9873px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.73px;
  font-family: Filson Pro;
  min-width: 220px;
}
.green-btn:hover {
  background-color: #00b25d;
  color: white;
}

.a1-box {
  background: #ffffff;
  border-radius: 18.5383px;
  box-shadow: 0px 2.69284px 23.5624px rgba(0, 0, 0, 0.04);
}
.a1-box h6 {
  font-family: Filson Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.02px;
  text-transform: capitalize;
  color: #000000;
}

@media (max-width: 576px) {
  .main-btn,
  .trans-btn,
  .purple-outline-btn,
  .purple-btn,
  .orange-btn,
  .green-btn {
    padding: 13px 25px;
    border-radius: 46.9873px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.73px;
    min-width: 150px;
  }
  .stat-section-content .stat h3 {
    font-size: 2.5rem;
    letter-spacing: -1.95px;
    font-weight: 700;
  }
  .stat-section-content .stat h6 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: -1.95px;
  }
}
@media (max-width: 376px) {
  .main-btn,
  .trans-btn,
  .purple-outline-btn,
  .purple-btn,
  .orange-btn,
  .green-btn {
    padding: 10px 20px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.73px;
    min-width: 100px;
  }
}
@media (max-width: 316px) {
  .main-btn,
  .trans-btn,
  .purple-outline-btn,
  .purple-btn,
  .orange-btn,
  .green-btn {
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: -0.73px;
    min-width: 80px;
  }
  .app-btn {
    width: 100px;
  }
}
.success-box h1 {
  font-family: Filson Pro;
  font-size: 23.2332px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.download-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index: 10000;
}
.download-modal-box {
  background-color: #fff;
  min-height: 200px;
  border-radius: 24px;
  position: relative;
}
.download-modal-box .close {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: transparent;
  outline: none;
  border: none;
}
.download-modal-box-header {
  font-family: Copperplate Bold;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: -2px;
}
.download-modal-box-body button {
  display: block;
  position: relative;
  outline: none;
  padding: 3px 25px;
  text-align: center;
  border-radius: 32px;
  background-color: #4b9dd8;
  border: 1px solid #4b9dd8;
  color: #000;
  font-weight: 600;
  margin-bottom: 14px;
}
.download-modal-box-body button span.text {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
}
.download-modal-box-body button span svg {
  width: 20px;
}
.download-modal-box-body button .coming-soon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: end;
}
.download-modal-box-body button .coming-soon img {
  width: 60px;
  rotate: 15deg;
}/*# sourceMappingURL=style.css.map */