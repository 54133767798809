.service-header { 
    min-height: 85vh;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(../images/service.jpg);
    // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661281201/a1tutor/newww_nppu14.jpg);
    background-position: center left;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &-content {
        font-family: $main-font;
        h1 {
            font-size: 3.5rem;
            font-weight: 600;
            line-height: 70px;
            letter-spacing: -2px; 
            color: #fff;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02px;
            font-weight: 500;
            color: #fff;
        }
    }
}

.service-info {
    h6 {
        font-family: $main-font;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.01px;
        line-height: 24px;
    }
}


@media (max-width: 576px) {
    .service-header { 
        min-height: 100vh;
        &-content { 
            h1 {
                font-size: 3rem; 
                line-height: 50px;
                letter-spacing: -2px; 
            }
            p {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    
    .service-info {
        h6 { 
            font-size: 15px; 
        }
    }
}

@media (max-width: 340px) {
    .service-header { 
        min-height: 120vh; 
        &-content { 
            h1 {
                font-size: 2.5rem; 
            } 
        }
    }
}