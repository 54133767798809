.register {
    overflow-x: hidden;
    &-content {
        min-height: 100vh;
        background-color: $main-blue;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: $main-font;
        h1 {
        font-weight: 600;
        font-size: 45.1829px;
        line-height: 56px;
        letter-spacing: -4.03419px;
        color: #fff;
            span {
                color: #e4e0ff;
            }
        }

        h6 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing:-1px ;
        }
        &-img {
            position: relative;
            display: grid;
            place-content: center;
            img {
                z-index: 100;
                // position: absolute;
            }
            &::before {
                content: "";
                position: absolute;
                background-color: rgba(255, 255, 255, 0.3); 
                width: 40%;
                height: 40%; 
                top: 30%;
                left: 30%;
                border-radius: 50%;
                // z-index: -1; 
                animation: rippleD 2.5s ease-out infinite;
              }
              &::after {
                content: "";
                position: absolute;
                background-color: rgba(255, 255, 255, 0.8); 
                width: 40%;
                height: 40%; 
                top: 30%;
                left: 30%;
                border-radius: 50%;
                // z-index: -1; 
                animation: ripple 1.5s ease-out infinite;
              }
        }
    }
    &-form {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $main-font;
        &-card {
            background: #FFFFFF;
            box-shadow: 5px 24px 40px 12px rgba(58, 157, 226, 0.04);
            border-radius: 16px;
            &-header {
                h3 {
                    font-weight: 500;
                    font-size: 24px;   
                    letter-spacing: -0.05em;
                    line-height: 35.52px;
                }

                h6 { 
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;  
                    letter-spacing: -0.02em; 
                    color: #4B5667;
                }

            }
            form {
                input {
                    padding: 22px 10px; 
                    border-radius: 4px;
                    width: 100%;
                    background-color: rgba(237, 237, 237, 0.7);
                    font-size: 14px;
                    letter-spacing: -1px;
                    outline: none; 
                    color: rgba($color: #000000, $alpha: 0.45);
                    line-height: 16px;
                    font-weight: 500;
                    border: 1px solid #f5f5f5;
                }
                button {
                    padding: 16px 32px; 
                    min-width: 176px;
                    min-height: 48px; 
                    background: rgba(58, 157, 226, 0.1);
                    border: 1px solid #3A9DE2;
                    border-radius: 80px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;  
                    letter-spacing: -1px;

                    color: #3A9DE2;

                }
            }
        }
    }
}

@media (max-width: 576px) {
    .register { 
        &-content { 
            h1 { 
                font-size: 2.5rem;
                line-height: 40px;  
                margin-bottom: 10px;
                }
        
                h6 { 
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing:1px ;
                }
        }
    }
}

@keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(3);
    }
  }

  @keyframes rippleD {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }