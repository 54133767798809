$size: 30px;

.bubble-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: -1;
  img,
  div {
    width: $size;
    height: $size;
  }

  .x {
    animation: x 30s linear infinite alternate;
  }

  .y {
    animation: y 7s linear infinite alternate;
  }

  .a {
    animation: a 15s linear infinite alternate;
  }

  .b {
    animation: b 12s linear infinite alternate;
  }

  .c {
    animation: a 20s linear infinite alternate;
  }
 
  .d {
    animation: b 22s linear infinite alternate;
  }

  .e {
    animation: a 12s linear infinite alternate;
  }

  .f {
    animation: b 15s linear infinite alternate;
  }

  //horizontal
  @keyframes x {
    100% {
      transform: translateX(calc(100vw - #{$size}));
    }
  }

  @keyframes a {
    100% {
      transform: translateX(calc(100vw - #{$size}));
    }
  }

  //vertical
  @keyframes y {
    100% {
      transform: translateY(calc(80vh - #{$size}));
    }
  }

  @keyframes b {
    100% {
      transform: translateY(calc(80vh - #{$size}));
    }
  }
}
