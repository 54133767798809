.about-header {
  min-height: 85vh;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url(https://res.cloudinary.com/oluwatoyinah/image/upload/v1661276319/a1tutor/santi-vedri-O5EMzfdxedg-unsplash_myuv2r.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &-content {
    font-family: $main-font;
    h1 {
      font-size: 3.5rem;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -2px;
      color: #fff;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.08px;
      font-weight: 500;
      color: #fff;
    }
  }
}

.about-header-section {
  min-height: 70vh;
  font-family: $main-font;
  position: relative;
  .about-header-main-title {
    font-size: 5rem;
    font-weight: 600;
    color: $dark-blue;
    letter-spacing: -2px;
    line-height: 6rem;
  }
  h6 {
    font-size: 20px;
    color: $dark-blue;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
    line-height: 35px;
  }

  .img-two {
    width: 250px;
    position: absolute;
    top: -5%;
    right: 5%;
    animation: circular 10s infinite 3s ease-in-out;
  }

  .img-three {
    width: 250px;
    position: absolute;
    bottom: -5%;
    right: 5%;
    rotate: -30deg;
    animation: moveUpandDown 5s infinite 3s ease-in-out;
  }

  .book {
    width: 140px;
    position: absolute;
    top: 20%;
    right: 15%;
    rotate: 30deg;
    animation: scaleUpandDown 5s infinite 3s ease-in-out;
  }
}

.about-us {
  font-family: $main-font;
  &-header {
    h3 {
      font-size: 3rem;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -2px;
    }
  }

  &-content {
    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      font-weight: 400;
      color: #0e0e0e;
    }
  }
}

.value__section {
  font-family: $main-font;
  // text-align: center;
  &-header {
    h3 {
      font-size: 3rem;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -3px;
      color: $dark-blue;
    }
  }

  .value-box {
    background-color: $main-blue;
    color: #fff;
    padding: 25px;
    display: flex;
    align-items: center;
    min-height: 250px;
    border-radius: 20px;
    font-family: $main-font;
    h2 {
      font-size: 40px;
      // font-size: 36px;
      font-weight: 700;
      line-height: 70px;
      letter-spacing: -1.5px;
    }

    h3 {
      font-size: 30px;
      line-height: 45px;
      font-weight: 600;
      letter-spacing: -1.5px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0784237px;
    }
  }

  .value-box.one {
    background-color: $main-blue;
  }

  .value-box.two {
    background-color: $red;
  }

  .value-box.three {
    background-color: $purple;
  }

  .value-box.four {
    // background-color: $yellow;
    background-color: $green;
  }

  .value-box.five {
    // background-color: $dark-blue;
    // background-color: $lemon;
    background-color: $main-blue;
  }
  // h5 {
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: -0.0784237px;
  //   color: #0e0e0e;
  //   font-weight: 600;
  // }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0784237px;
    // color: #0e0e0e;
  }
}

.value-section {
  overflow-x: hidden;
  background-color: $grey;
  &-content {
    font-family: $main-font;
    &-header {
      h3 {
        font-weight: 600;
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -2px;
      }
    }
    h5 {
      font-size: 1.2rem;
      line-height: 24px;
      letter-spacing: -0.6px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.2px;
    }
  }
}

.curriculum-section {
  overflow-x: hidden;
  background-color: $grey;
  &-content {
    font-family: $main-font;
    &-header {
      h3 {
        font-weight: 600;
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -2px;
      }
    }
    h5 {
      font-size: 33.7052px;
      line-height: 24px;
      letter-spacing: -1px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.2px;
    }

    ul {
      margin-left: 0;
      li {
        letter-spacing: -1px;
      }
    }
  }
}

@media (max-width: 767px) {
  .about-header-section {
    .about-header-main-title {
      font-size: 4rem;
      line-height: 4.3rem;
    }

    .img-two {
      width: 150px;
      top: 0%;
    }
  }
}

@media (max-width: 576px) {
  .about-header {
    min-height: 95vh;

    &-content {
      h1 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: -1.5px;
        color: #fff;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02px;
        font-weight: 500;
        color: #fff;
      }
    }
  }

  .about-header-section {
    min-height: 70vh;
    font-family: $main-font;
    position: relative;
    .about-header-main-title {
      font-size: 2.6rem;
      font-weight: 600;
      color: $dark-blue;
      letter-spacing: -1px;
      line-height: 3rem;
    }
    h6 {
      font-size: 17px;
      color: $dark-blue;
      margin-bottom: 10px;
      letter-spacing: -0.5px;
      line-height: 25px;
    }

  .img-two { 
    top: -3%;
    right: -5%;
  }

  }
  .about-us {
    &-header {
      h3 {
        font-size: 2.5rem;
        line-height: 50px;
      }
    }

    &-content {
      p {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  .value-section {
    &-content {
      &-header {
        h3 {
          font-size: 2.5rem;
          line-height: 50px;
          letter-spacing: -2px;
        }
      }
      h5 {
        font-size: 1.2rem;
        line-height: 24px;
        letter-spacing: -0.6px;
        font-weight: 600;
      }
      p {
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.2px;
      }
    }
  }

  .curriculum-section {
    &-content {
      &-header {
        h3 {
          font-weight: 600;
          font-size: 2.5rem;
          line-height: 50px;
          letter-spacing: -2px;
        }
      }
      h5 {
        font-size: 1.5rem;
      }
      p {
        font-size: 15px;
        letter-spacing: -0.2px;
      }
    }
  }
}

@media (max-width: 340px) {
  .about-header {
    min-height: 120vh;
    &-content {
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}

@keyframes circular {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes moveUpandDown {
  0% {
    transform: translateY(0);
  }
  // 25% {
  //   transform: translateY(-50px);
  // }
  50% {
    transform: translateY(-50px);
  }
  // 75% {
  //   transform: translateY(50px);
  // }
  100% {
    transform: translateY(0);
  }
}

@keyframes scaleUpandDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
