// Colors
$main-blue: #4b9dd8;
$main-light-blue: #2d9cdb;
$light-blue: rgba(46, 123, 179, 0.2);
$dark-blue: #112957;
$grey: #f5f5f5;
$purple: #7769e2;
$lemon: #02c869;
$yellow: #ffce31;
$orange: #ff8736;
$green: #00b25d;
$red: #eb5757;

// Fonts
$main-font: Filson Pro;
$sub-font: "Nunito", sans-serif;
$header-font: Copperplate Bold;
$DM-san: "Source Sans Pro", sans-serif;
$fraunce: "Fraunces", serif;

@font-face {
  font-family: Filson Pro;
  src: url("../fonts/16919/Filson\ Pro/webfont/FilsonProBook.woff2")
      format("woff2"),
    url("../fonts/16919/Filson\ Pro/webfont/FilsonProBook.woff") format("woff");
}

@font-face {
  font-family: Copperplate Bold;
  src: local("Copperplate"),
    url("../fonts/CopperplateCC-Full/CopperplateCC/otf/CopperplateCC-Bold.otf")
      format("truetype"),
    url("../fonts/CopperplateCC-Full/CopperplateCC/otf/CopperplateCC-Bold.otf")
      format("otf");
}
