.contact-section {
  font-family: $main-font;
  min-height: 40vh;
  &-header {
    position: relative;
    h3 {
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -3px;
    }
  }
  &-info {
    min-height: 40vh;
    background-image: linear-gradient(
        0deg,
        rgba(119, 105, 226, 0.7),
        rgba(119, 105, 226, 0.7)
      ),
      url('../images/contact-boys.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 33.57px;
      letter-spacing: -0.6px;
    }

    img {
      width: 27px;
    }
  }

  &-form {
    form {
      input,
      textarea {
        padding: 25px 18px;
        width: 90%;
        border-radius: 8px;
        background-color: rgba(237, 237, 237, 0.7);
        font-size: 16px;
        letter-spacing: -0.8px;
        outline: none;
        color: rgba($color: #000000, $alpha: 0.45);
        line-height: 28.4px;
        font-weight: 500;
        border: 1px solid #f5f5f5;
      }
    }
  }
}

@media (min-width: 1400px) {
  .contact-section {
    font-family: $main-font;
    min-height: 40vh;
    &-header {
      position: relative;
      // text-align: justify;
      // &::after {
      //     content: attr(extratext);
      //     position: absolute;
      //     inset: 0;
      //     display:inline-block;
      //     width: 100%;
      //     height: 100%;
      // }
      h3 {
        font-size: 2.25rem;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -3px;
      }
    }
    &-info {
      min-height: 40vh;
      background-image: linear-gradient(
          0deg,
          rgba(119, 105, 226, 0.7),
          rgba(119, 105, 226, 0.7)
        ),
        url('../images/contact-boys.jpeg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 33.57px;
        letter-spacing: -0.8px;
      }

      img {
        width: 27px;
      }
    }

    &-form {
      form {
        input,
        textarea {
          padding: 37px 21px;
          width: 90%;
          border-radius: 8px;
          background-color: rgba(237, 237, 237, 0.7);
          font-size: 16px;
          letter-spacing: -0.8px;
          outline: none;
          color: rgba($color: #000000, $alpha: 0.45);
          line-height: 33.4px;
          font-weight: 500;
          border: 1px solid #f5f5f5;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .contact-section {
    min-height: 80vh;
    &-header {
      h3 {
        font-size: 2.2rem;
        line-height: 38px;
      }
    }
    &-info {
      min-height: 50vh;
      p {
        font-size: 16px;
        line-height: 33.57px;
        letter-spacing: -0.8px;
      }

      img {
        width: 20px;
      }
    }

    &-form {
      form {
        input,
        textarea {
          width: 100%;
          padding: 15px 15px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .contact-section {
    &-form {
      form {
        input,
        textarea {
          width: 100%;
        }
      }
    }
  }
}
